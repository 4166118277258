import React from "react";
import NavBar from "./routes";
import "./index.scss";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import auth from "./services/auth";

const getCurrentUser = auth.getCurrentUser

export const ThemeContext = React.createContext({}); //color: "#184090"

class RouteContainer extends React.PureComponent {
  state = {
    user: null,
    loading: true,
    adminLoggedIn: false,
  };

  async componentDidMount() {
    // console.log = () => { };
    let user = await getCurrentUser();
    if (user && !user.error) {
      this.setState({ user, loading: false });
    }
    else {
      this.setState({ loading: false });
    }
  }

  onLogin = async (router: any) => {
    let user = await getCurrentUser();
    if (user && !user.error) {
      this.setState({ user });
      router.push("/");
      window.location.href = "/"
    }
  };

  render() {
    const { user } = this.state;
    return (
      <ThemeContext.Provider value={{ color: "#184090" }}>
        <ReactNotification />
        {this.state.loading ? null : (
          <NavBar user={user} />
        )}
      </ThemeContext.Provider>
    )
  }
}

export default RouteContainer;