import React, { Component } from "react";
import DataTable from "../../components/table";
import { ActionPara, HeadCell } from "../../components/table/type";
import { ArrowBack } from "@material-ui/icons";
import "./style.scss";
import { withRouter } from "react-router-dom";
import { Button } from "@material-ui/core";
import Loader from "../../components/loader";
import { notifError } from "../util";
import { getTravellersAPIs } from "../../services/api-services/users";
import moment from "moment";

const headCells: HeadCell[] = [
	{ name: "name", label: "Name" },
	{ name: "email", label: "Email" },
	{ name: "gender", label: "Gender" },
	{ name: "age", label: "Age" },
	{ name: "type", label: "Type" },
	{ name: "action", label: "Action" }
];
const getActions = (history: any, traveller: TravellerType, user: string): ActionPara[] => [
	{
		name: "Update",
		action: () =>
			history.push({
				pathname: "/users/travellers/update/" + user,
				state: traveller
			}),
	},
];
const parseTraveller = (travellers: TravellerType[], history: any, user: string): TableTravellerType[] => (
	travellers.map(({ firstName, lastName, middleName, id, dob, isMain, ...rest }: TravellerType) => ({
		...rest,
		id,
		name: `${firstName} ${middleName} ${lastName}`,
		type: isMain ? "Primary" : "",
		age: moment().diff(moment(dob, 'YYYY-MM-DD'), 'years'),
		action: getActions(history, { firstName, lastName, middleName, id, dob, isMain, ...rest }, user)
	}))
)
export type TravellerType = {
	dob: string;
	email: string;
	firstName: string;
	gender: string;
	id: number;
	idExpiry: string;
	idNumber: string;
	idType: string;
	isMain: boolean;
	lastName: string;
	middleName: string;
	status: boolean;
	user_id: number;
}
export type TableTravellerType = {
	email: string;
	name: string;
	gender: string;
	id: number;
	idExpiry: string;
	idNumber: string;
	idType: string;
	type: any;
	age: number;
	action: any;
}
class Travellers extends Component<
	any,
	{
		travellers: TableTravellerType[];
		loading: boolean;
		name: string;
	}
> {
	state = {
		travellers: [],
		loading: true,
		name: "",
	};

	async componentDidMount() {

		let res = await getTravellersAPIs(this.props.match.params.id)
		if (res.status) {
			let travellers: TableTravellerType[] = parseTraveller(res.data || [], this.props.history, this.props.match.params.id);
			this.setState({ travellers, loading: false })
		} else {
			this.setState({ loading: false });
			notifError("Travellers", "something went wrong.")
		}
	}

	render() {
		const { travellers, loading, name } = this.state;

		return (
			<div className="root-container">
				<Loader show={loading} />
				<Button
					className="back-btn"
					onClick={() => {
						this.props.history.push("/users");
					}}
				>
					<ArrowBack /> Back
				</Button>
				<DataTable
					title={"Travellers"}
					headers={headCells}
					data={travellers}
				>
					<div id="airports"></div>
				</DataTable>
			</div>
		);
	}
}

export default withRouter(Travellers);
