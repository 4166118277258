import React, { Component, Fragment } from "react";
import "./style.scss";
import moment from "moment";
import DottedLine from "../DottedLine";
import { AirportType } from "../select-airports";
import AIRPORTS from "../../routes/airports/airports.json";

const airports: any = AIRPORTS;

type RouteType = {
    iataCode: string;
    terminal: string;
    at: string;
}

type SegmentType = {
    departure: RouteType;
    arrival: RouteType;
    carrierCode: string;
    number: string
    aircraft: {
        code: string;
    },
    duration: string;
    id: string;
    numberOfStops: number;
    blacklistedInEU: boolean;
}

const getDuration = (start: string, end: string) => {
    const startDate = moment(new Date()); //todays date
    const endDate = moment("2015-12-1"); // another date
    const duration = moment.duration(startDate.diff(endDate));
    const hours = duration.hours();
    const min = duration.minutes();

    console.log("hours", hours)
    let d = "";
    if (hours && min)
        d = `${hours} h ${min}`
    if (!hours && min)
        d = `${min} m`
    else
        d = `${hours} h`
    return `${hours} h ${duration.minutes()}`
}
const getAirportByCode = (code: string): AirportType | null => {
    if (code && code.length)
        return airports.airports.find(({ iataCode, icaoCode }: AirportType) => (
            iataCode?.toUpperCase() === code.toUpperCase() ||
            icaoCode?.toUpperCase() === code.toUpperCase()))
    return null;
}
const airportsName: { [x: string]: string; } = {};

const getAirportName = (code: string) => {
    if (!airportsName[code]) {
        const air = getAirportByCode(code);
        if (air) {
            let name = air.name || air.country || "";
            name = name.replace("International", "Intl.");
            airportsName[code] = name;
            return name;
        } else {
            airportsName[code] = "";
            return "";
        }
    }
    return airportsName[code];
};
const durationFormat = (d: string) => {
    if (d)
        return d.replace("PT", "").replace("H", " h ").replace("M", " m").trim();
    return ""
}

export type ItinerarieType = {
    duration: string;
    segments: SegmentType[];
}

const formatDate = (date: string, format: string) => {
    return moment(date).format(format)
}

class Trips extends Component<{ flight: ItinerarieType[]; pnr: string; }> {
    render() {
        const { flight, pnr } = this.props;
        return (
            <div id="my-trips">
                <div className="open trip-root">
                    <div className="s-des">{flight[0].segments[0].departure.iataCode} - {flight[1].segments[0].departure.iataCode}</div>
                    <div className="cancel"></div>
                    <div className="arrow-down"></div>
                </div>
                <div className="trip-details">
                    <div className="details-root">
                        <div className="heading">Departure</div>
                        <div className="value">{moment(flight[0].segments[0].departure.at).format("DD MMM, YYYY")}<div className="dot"></div>
                            {moment(flight[0].segments[0].departure.at).format("HH:MM")}</div>
                        <div className="value">{getAirportName(flight[0].segments[0].departure.iataCode)}</div>
                    </div>
                    <div className="details-root">
                        <div className="heading">Return</div>
                        <div className="value">{moment(flight[1].segments[0].departure.at).format("DD MMM, YYYY")}<div className="dot"></div>
                            {moment(flight[1].segments[0].departure.at).format("HH:MM")}</div>
                        <div className="value">{getAirportName(flight[1].segments[0].departure.iataCode)}</div>
                    </div>
                    <div className="details-root">
                        <div className="heading">Booking Id</div>
                        <div className="value">{pnr}</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Trips;


const flightDetails = ({ duration, departure, arrival }: SegmentType, index: number) => (
    <Fragment key={index}>
        {index !== 0 && (
            <div className="layover">
                <div>{getDuration(departure.at, arrival.at)}</div>
                <span className="dot"></span>
                <div>
                    {getAirportName(arrival.iataCode)} ({departure.iataCode})
          </div>
            </div>
        )}
        <div className="lower">
            <div className="fixed-width">
                <div className="time">{formatDate(departure.at, "DD MMM,YYYY")}</div>
                <div className="date">{formatDate(departure.at, "HH:MM")}</div>
                <div className="name">
                    {getAirportName(departure.iataCode)} ({departure.iataCode})
          </div>
            </div>
            <div className="dots">
                <div className="duration">{getDuration(departure.at, arrival.at)}</div>
                <DottedLine vertical={false} classes={{}} className={"m-0"} />
            </div>
            <div className="fixed-width align-right">
                <div className="time">{formatDate(arrival.at, "DD MMM,YYYY")}</div>
                <div className="date">{formatDate(arrival.at, "HH:MM")}</div>
                <div className="name">
                    {getAirportName(arrival.iataCode)} ({arrival.iataCode})
          </div>
            </div>
        </div>
    </Fragment>
);
const selectedFlight = (flight: ItinerarieType, index: number) => {
    const {
        segments,
        duration
    } = flight;
    return (
        <div className="flight-details-container" key={index}>
            <div className="upper">
                <div className="box">
                    <div className="flight">
                        <div className="code">
                            {segments[0].departure.iataCode} - {segments[segments.length - 1].arrival.iataCode}
                        </div>
                        <div className="duration">
                            {segments.length - 1 === 0 ? "No" : segments.length - 1} Stop | {getDuration(segments[0].departure.at, segments[segments.length - 1].arrival.at)}
                        </div>
                    </div>
                </div>
                <div className="box btn-box">
                    <span className="btn noselect">{index === 0 ? "Depart" : "Return"}</span>
                </div>
            </div>
            {segments.map(flightDetails)}
        </div>
    );
};

export const FlightDetails: React.FC<{ flights: ItinerarieType[] }> = ({ flights }) => {
    return (
        <div className="confirmation-root">
            <h3 className="title">Flight details</h3>
            <div className="confirmation-container">
                {flights.map(selectedFlight)}
            </div>
        </div>
    )
}