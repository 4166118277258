import React, { useCallback } from "react";
import { Input, Select, MenuItem } from "@material-ui/core";
import countries from "./countries.json";

const DropDownIcon = () => <svg width="20" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0.99086 0.665273C0.664193 0.991939 0.664193 1.51861 0.99086 1.84527L6.53086 7.38527C6.79086 7.64527 7.21086 7.64527 7.47086 7.38527L13.0109 1.84527C13.3375 1.51861 13.3375 0.99194 13.0109 0.665273C12.6842 0.338607 12.1575 0.338607 11.8309 0.665273L6.99753 5.49194L2.16419 0.658606C1.84419 0.338606 1.31086 0.338606 0.99086 0.665273Z" fill="white" />
</svg>


let country_codes = countries.countries;


const countryList = () => {
  const renderCountryCode = ({ name }: { name: string }, index: number) => (
    <option key={index} id={index.toString()} value={name}>
      {name}
    </option>
  );
  return country_codes.map(renderCountryCode);
}


interface Props {
  icon?: any;
  variant: string;
  className: string;
  inline: boolean;
  labelText: string;
  labelPositionLeft: Boolean;
  labelCss: string;
  form: any;
  field: any;
  value: string | number;
  type?: string;
  create_err_msg?: any;
}
const CountryField: React.FC<Props> = ({
  form: { touched, errors, setFieldValue, handleChange },
  field: { name, value },
  icon,
  variant,
  labelCss,
  labelPositionLeft,
  ...rest
}) => {

  return (
    <div className={`${labelPositionLeft && "flex-box-selection"} primary`}>
      <div className={`label-text-selection ${labelCss}`}>{rest.labelText}</div>
      {icon && <div className="icon-root">{icon}</div>}
      <Select
        native
        style={{ width: "100%" }}
        labelId="demo-mutiple-name-label"
        className={`MuiInput-root ${!icon && "p-l-0"}`}
        id="demo-mutiple-name"
        onChange={handleChange(name)}
        IconComponent={DropDownIcon}
        input={<Input />}
        value={value || "United States"}
      >
        {countryList()}
      </Select>
      {
        errors[name] && touched[name] && (
          <div className="error-text">
            {rest.create_err_msg
              ? rest.create_err_msg(value)
              : errors[name]}
          </div>
        )
      }
    </div >
  );
}

export default CountryField;
