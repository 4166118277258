export const style = () => ({
  horizontalView: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    alignItems: "center",
    margin: "0px 3px",
  },
  horizontalLine: {
    width: "100%",
    borderTop: "1px dashed #e0e0e0",
  },
  verticalLine: {
    height: 26,
    margin: 4,
    borderLeft: "1px dashed white",
  },
  circleBorder: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    background: "white",

  },
  verticalView: {
    width: 6,
    paddingTop: 6
  },
});
