import React from "react";
import Modal from '@material-ui/core/Modal';
import './style.scss'

const ModalComponent=({open,children})=>{   
    return(
        <Modal open={open} id="modal-component">
            <div className="child-component">{children}</div>            
        </Modal>
    )
}
export default ModalComponent;