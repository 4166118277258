import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Input from "./inputField";

interface Props {
  variant: string;
  className: string;
  inline: boolean;
  labelText: string;
  labelPositionLeft: Boolean;
  labelCss: string;
  form: Object;
  field: Object;
  value: string | number;
}

const CustomizedInputs: React.FC<Props> = React.memo(
  ({ variant, className, ...rest }) => {
    return <Input variant={variant} className={className} {...rest} />;
  }
);

export default CustomizedInputs;
