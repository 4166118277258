import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { CircularProgress } from '@material-ui/core';
import "./style.scss";

type props_type = {
  show: boolean;
};

export default ({ show }: props_type) => {
  return (
    <Dialog fullScreen open={show} id="full-loader">
      <div className="fullBlock">
        {/* <img src={Logo} alt="" className="loader-img" /> */}
        <div className="loader">
          <CircularProgress color="secondary" className="loader-color" />
        </div>
      </div>
    </Dialog>
  );
};
