import React, { Component } from "react";
import DataTable from "../../components/table";
import { HeadCell } from "../../components/table/type";
import "./style.scss";
import { Link, withRouter } from "react-router-dom";
import Button from "../../components/button";
import Loader from "../../components/loader";
import { parsePlans } from "./utils";
import { notifError, notifSuccess } from "../util";
import { deletePlanAPIs, getAllPlanAPIs } from "../../services/api-services/plans";

const headCells: HeadCell[] = [
    { name: 'name', label: 'Name' },
    { name: 'month', label: 'No. of Month' },
    { name: 'flight_class', label: 'Flight Class' },
    { name: 'price', label: 'Price' },
    { name: 'flights', label: 'No. Of Flights' },
    { name: 'country', label: 'Country' },
    { name: 'status', label: 'Status' },
    { name: "action", label: "Action" }
];
export type PlansType = {
    description: string;
    flight_class: string;
    id: number;
    month: number;
    status: string;
    name: string;
    price: number;
    action?: any;
    flights: number;
    country: string;

}

class Plans extends Component<any, {
    loading: boolean;
    plans: PlansType[];
}> {
    state = {
        plans: [],
        loading: true
    }

    async componentDidMount() {
        this.setState({ loading: true })
        let res = await getAllPlanAPIs()
        console.log("res", res)
        if (res.status)
            this.setState({ loading: false, plans: parsePlans(res.data, this.props.history) })
        else {
            notifError("Tags", "something went wrong.")
            this.setState({ loading: false })
        }
    }
    onDeletePlans = async (ids: string[]) => {
        let { plans } = this.state;
        this.setState({ loading: true })
        for (let id of ids) {
            let res = await deletePlanAPIs(id);
            if (res.status) {
                let deleted: PlansType | any = plans.find((item: any) => item.id.toString() == id);
                if (deleted) {
                    plans = plans.filter((item: any) => item.id.toString() !== id);
                    notifSuccess("Plans", `${deleted.name} Plan has been deleted successfully.`)
                }
            } else notifError("Plans", "something went wrong")
        }
        this.setState({ plans, loading: false })
    };
    render() {
        const { plans, loading } = this.state;
        return (
            <div className="root-container">
                <Loader show={loading} />
                <DataTable
                    onDelete={this.onDeletePlans}
                    title={"Plans"}
                    headers={headCells}
                    data={plans}
                >
                    <Link to="/plans/add">
                        <Button variant="contained" btnText={"+  Plan"} onClick={() => { }} />
                    </Link>
                </DataTable>
            </div>
        );
    }
}

export default withRouter(Plans);