import moment from "moment";

function parseReqData(params: any) {
    return {
        "firstName": params.name['First name'],
        "lastName": params.name['Last name'],
        "middleName": params.name['Middle name'] || "",
        "email": params.email,
        "dob": moment(params['Date of birth']).format("YYYY-MM-DD"),
        "idType": params['ID type'].toUpperCase(),
        "idExpiry": moment(params["ID expiry"]).format("YYYY-MM-DD"),
        "idNumber": params["ID number"],
        "gender": params.gender?.toUpperCase(),
        countryCode: params.countryCode,
        nation: params.nationality,
        mobile: params.mobile,
    }
}
function parseDataReq(params: any) {
    return {
        id: params["id"],
        name: `${params["firstName"] || ""} ${params["middleName"] || ""} ${params["lastName"] || ""}`,
        "email": params.email,
        "Date of birth": new Date(params['dob'] || "") || new Date(),
        "ID type": params['idType'],
        "ID expiry": new Date(params["idExpiry"] || "") || new Date(),
        "ID number": params["idNumber"],
        "gender": params.gender,
        nationality: params.nation,
        "Mobile No": `${params.countryCode}${params.mobile}`,
        countryCode: params.countryCode,
        mobile: params.mobile,
    }
}
export {
    parseReqData,
    parseDataReq
}