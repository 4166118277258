import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
const header_styles = () =>
  createStyles({
    root: {
      padding: "30px 20px",
      color: "white",
    },
  });

interface Props {
  toggleDrawer: () => void;
  classes: any;
}

const Header: React.FC<Props> = ({ classes, toggleDrawer }) => {
  return (
    <div className={classes.root}>
      <Menu onClick={toggleDrawer} />
    </div>
  );
};

export default React.memo(withStyles(header_styles)(Header));
