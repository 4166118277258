import { useState, useEffect, useCallback } from 'react';
import './style.scss';
import GoogleImageSearch from './search'
import loadingGif from './loading.gif'
import Modal from "../modal";
import { Image as ImageIcon, Close as CloseIcon } from "@material-ui/icons";
import imgicon from '../../assets/imgicon.png'

function GImagePicker({ onImageSelect }) {
  const [modal,setModal]=useState(false);
  const [list, setList] = useState([])
  const [query, setValue] = useState('')
  const [loading, setLoading] = useState()
  const [selected, select] = useState()

  const load=()=>{
    setList(JSON.parse(localStorage.getItem("saved") || '[]'));      
  }

  const saveToLocal = (latest) => {
    const exiting = JSON.parse(localStorage.getItem("saved") || '[]')
    const collection = Array.from(new Set([...exiting, ...latest]))
    localStorage.setItem("saved", JSON.stringify(collection))
  }

  const onChange = async(evt) => {
    setValue(evt.target.value)    
  }

  const search = () => {
    setLoading(true)
    GoogleImageSearch.searchImage(query)
    .then((res) => {
      saveToLocal(res)
      setList(l => [...res, ...l])
      setLoading(false)
    })
  }

  const getBase64FromUrl = async (url, size = 400) => new Promise((resolve)=>{
    const image = new Image()
    image.crossOrigin="anonymous"
    image.onload = () => {
    	const canvas = document.getElementById('cnvs')
      let width = size
      let height = size
      if (image.naturalWidth > image.naturalHeight) {
        height = width / 2
      } else if (image.naturalWidth < image.naturalHeight) {
        width = height / 2
      }

      canvas.width = size
      canvas.height = size
      const ctx = canvas.getContext('2d')
      ctx.drawImage(image, 0, 0, size, size)
      const base64 = canvas.toDataURL('image/jpeg', 0.1);
      resolve(base64)
    }
    
    image.src = url
  })
  

  const onSelect = useCallback((url) => () => {   
    let previousResult=JSON.parse(localStorage.getItem("default-Image"))||{};       
    previousResult[`${query}`] =url; 
    if(typeof onImageSelect === 'function')
      select(url)
      getBase64FromUrl(url, 600).then((resp)=>{           
        onImageSelect(resp);
        setModal(false);
      })      
  })

  const handleModal=()=>{
    load()
    setModal(true);
  }

  const closeModal=()=>{
    setList([])
    setModal(false);
  }

  return (
    <>
       <div id="image-input" onClick={handleModal}>
        <img width="100%" height="80" width ="80" src={selected || imgicon} /> 
        <span>Select image</span>
       </div>

      <Modal open={modal}>
        <div className='container'>

          <div className='searchbar'>
            <input type="text" placeholder="Type to search..." onChange={onChange}/>
            <button className='searchbtn' disabled={query.length < 3} onClick={search}>Search</button>
            <div className='close-button'>
              <CloseIcon onClick={closeModal}/>
            </div>
          </div>
          {
            loading &&<div>Loading...</div>
          }
          
            <div className='grid'>                  
              { list.map(( url , idx)=>(
                <div className='tile' onClick = {onSelect(url)}>
                  <img width="100%" src={url} key={url}/>
                </div>
              ))}
            </div>
        </div>
      </Modal>
      
      <canvas id="cnvs" style={{display: 'none'}}></canvas>
    
    </>
  );
}

export default GImagePicker;
