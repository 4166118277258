import React, { Component } from "react";
import "./style.scss";
import Form from "../../components/FormComponents";
import { fields } from "./fields"
import RightPannel from "../../components/Authentication/right-pannel";
import Button from "../../components/button";
import SelectAirports, { AirportType } from "../../components/select-airports";
//import ImageInput from "../../components/image-input";
import { addTagsAPI } from "../../services/api-services/tags";
import auth from "../../services/auth";
import Loader from "../../components/loader";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { notifError, notifSuccess } from "../util";
import RelatedTags, { RelatedTagType } from "../../components/related-tags";
import TagLevel from "../../components/related-tags/level";
import { addNewTagInDB } from "../../indexedDB/tags";
import ImagePicker from '../../components/GooglSearch'

interface StateParams {
    img: any;
    airports: AirportType[];
    loading: boolean;
    relatedTags: RelatedTagType[];
    levelValue: number;
}
const initialValues = { name: "", status: "true" }
class AddTags extends Component<RouteComponentProps, StateParams> {
    state = {
        img: undefined,
        airports: [],
        relatedTags: [],
        loading: false,
        levelValue: 0,
    }
    onLevelChange = (e: any) => {
        let levelValue: number = e.target.value
        this.setState({ levelValue })
    }
    handleImage = (img: any) => {
        this.setState({ img })
    }
    handleAirports = (airports: any) => {
        this.setState({ airports })
    }
    handleRelatedTags = (relatedTags: any) => {
        this.setState({ relatedTags })
    }
    onSubmit = async (payload: any, resetForm: any) => {
        this.setState({ loading: true })
        const { airports, img, levelValue, relatedTags } = this.state;
        const airport = airports.map((port: AirportType) => port.id)
        let data = {
            name: payload.name,
            image: img,
            airports: airport,
            status: payload.status,
            level: levelValue,
            tags: relatedTags.map((tag: RelatedTagType) => tag.id)
        }
        let res = await addTagsAPI(data, auth.getToken())
        if (res.status) {
            await addNewTagInDB(res.data)
            notifSuccess("Tag", "Tag has been added successfully.")
            this.props.history.push("/tags")
        }
        else notifError("Tag", `something went wrong`)
        this.setState({ loading: false })
    }
    handleCancel = () => {
        this.props.history.push("/tags")
    }
    render() {
        return (
            <div className="root-container form-center">
                <Loader show={this.state.loading} />
                <div className="flexBox-root">
                    <div id="add-tags">
                        <RightPannel
                            className="rightPanel-container"
                            from={0} handleChange={() => { }}>
                            <div className="rightPanel">
                                <div className="title">Add New Tag</div>
                                <div className="line"></div>
                                <div className="form">
                                    <Form
                                        fields={fields}
                                        onSubmit={this.onSubmit}
                                        initialValues={initialValues}
                                        btnText={"Add"}
                                        renderCustomSubmit={
                                            <>
                                                {/* <ImageInput img={this.state.img} onSelectImg={this.handleImage} /> */}                                               
                                                <ImagePicker onImageSelect={this.handleImage} />                                               
                                                <SelectAirports addedAirports={this.state.airports} handleAirports={this.handleAirports} />
                                                <RelatedTags relatedTags={this.state.relatedTags} handleRelatedTags={this.handleRelatedTags} />
                                                <TagLevel value={this.state.levelValue} onLevelChange={this.onLevelChange} />
                                                <div className="bottomSection">
                                                    <Button onClick={this.handleCancel} btnText={"Cancel"} variant={"contained"} />
                                                    <Button onClick={() => { }} btnText={"Add"} variant={"contained"} />
                                                </div>
                                            </>
                                        }
                                    />

                                </div>
                            </div>
                        </RightPannel>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AddTags);