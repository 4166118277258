export class TokenService {

    static token = "";

    static setToken(token:string){
        TokenService.token = token;
    }

    static getToken():string{
        return TokenService.token
    }
}

export default TokenService;