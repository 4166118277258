import { Authentication } from "./interfaces";
import { AwsCognitoService } from "./services/aws-cognito-service";
import { awsCognito } from "./config";
import { UserParams, Response } from "./types";
import { TokenService } from "./token";
import { errorAlert } from "./settings";

const aws: Authentication = new AwsCognitoService(awsCognito);

const verifyResponse = (resp: Response) => {
  if (resp.error) errorAlert("Error", resp.message);
  return resp;
};

class AuthService {
  service = aws;
  login = async (email: string, password: string) => {
    let resp = await this.service.doLogin(email, password);
    return verifyResponse(resp);
  };
  changePassword = async (
    email: string,
    old_pass: string,
    new_pass: string
  ) => {
    let resp = await this.service.changePassword(email, old_pass, new_pass);
    return verifyResponse(resp);
  };
  logout = this.service.logout;
  getCurrentUser = async () => {
    let user: any = await this.service.getUser();
    if (user.email) {
      TokenService.setToken(user.jwtToken);
      return user;
    }
    return { error: true };
  };
  getToken = TokenService.getToken;
}
export default new AuthService();
