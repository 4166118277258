import { LocalOffer, Public, Description, AttachMoney, Today, FlightTakeoff, AirlineSeatReclineExtra } from "@material-ui/icons";
import CountryField from "../../../components/FormComponents/country";
import { field } from "../../../components/FormComponents/types";

const fields: field[] = [
    {
        name: "flights",
        placeholder: "No. of flights",
        type: "number",
        icon: <FlightTakeoff />,
        fieldType: "tripStarter",
        variant: "tripStarter",
    },
];


export {
    fields,
}