import React, { Component } from "react";
import "./style.scss";
import Form from "../../components/FormComponents";
import { fields } from "./fields"
import RightPannel from "../../components/Authentication/right-pannel";
import Button from "../../components/button";
import SelectAirports, { AirportType } from "../../components/select-airports";
import ImageInput from "../../components/image-input";
import { updateTagAPI } from "../../services/api-services/tags";
import auth from "../../services/auth";
import Loader from "../../components/loader";
import { withRouter, RouteComponentProps } from "react-router";
import { notifError, notifSuccess } from "../util";
import RelatedTags, { RelatedTagType } from "../../components/related-tags";
import TagLevel from "../../components/related-tags/level";
import { TagParams } from "../../services/api-services/type";
import { getObjectsFromIndexDB } from "../../components/select-airports/filter-airports";
import { addNewTagInDB, getTagByIdFromDB } from "../../indexedDB/tags";
import ImagePicker from '../../components/GooglSearch'

interface StateParams {
    image: any;
    airports: AirportType[];
    loading: boolean;
    initialValue: {
        name: string;
        status: string;
    }
    relatedTags: RelatedTagType[];
    levelValue: number;
}
const initialValue = { name: "", status: "true", }
class EditTags extends Component<RouteComponentProps, StateParams> {
    state = {
        image: undefined,
        airports: [],
        loading: true,
        initialValue,
        levelValue: 0,
        relatedTags: []
    }
    handleImage = (image: any) => {
        this.setState({ image })
    }
    onLevelChange = (e: any) => {
        let levelValue: number = e.target.value
        this.setState({ levelValue })
    }
    handleAirports = (airports: any) => {
        this.setState({ airports })
    }
    handleRelatedTags = (relatedTags: any) => {
        this.setState({ relatedTags })
    }
    async componentDidMount() {
        const { name: id }: any = this.props.match.params;
        let res = await getTagByIdFromDB(id);
        if (res) {
            const { image, name, status, level, tids, airids }: any = res;
            const airports = await getObjectsFromIndexDB("airports", JSON.parse(airids) || [])
            const relatedTags = await getObjectsFromIndexDB("tags", JSON.parse(tids) || [])
            this.setState({
                initialValue: { name, status: status.toString() },
                airports,
                image,
                relatedTags,
                levelValue: level || 0,
                loading: false
            })
        } else {
            notifError("Edit Tag", `something went wrong`)
            this.setState({ loading: false })
        }



    }
    onSubmit = async (payload: any, resetForm: any) => {
        this.setState({ loading: true })
        const { airports, levelValue, relatedTags } = this.state;
        const { name: id }: any = this.props.match.params;
        let data: TagParams = {
            name: payload.name,
            airports: airports.map((port: AirportType) => port.id),
            status: payload.status,
            level: levelValue,
            tags: relatedTags.map((tag: RelatedTagType) => tag.id)
        }
        const image: any = this.state.image;
        if (!image.includes("https://s3.us-east-1.amazonaws.com"))
            data.image = image;
        let res = await updateTagAPI(id, data, auth.getToken())
        if (res.status) {
            await addNewTagInDB(res.data);
            notifSuccess("Edit Tag", `${payload.name} tag has been updated successfully.`)
            this.props.history.push("/tags")
        } else notifError("Edit Tag", `something went wrong`)
        this.setState({ loading: false })
    }
    onCancel = () => {
        this.props.history.push("/tags")
    }
    render() {
        const { initialValue, airports, image, loading } = this.state;
        return (
            <div className="root-container form-center">
                <Loader show={loading} />
                <div className="flexBox-root">
                    <div id="add-tags">
                        <RightPannel
                            className="rightPanel-container"
                            from={0} handleChange={() => { }}>
                            <div className="rightPanel">
                                <div className="title">Edit Tag</div>
                                <div className="line"></div>
                                <div className="form">
                                    <Form
                                        fields={fields}
                                        onSubmit={this.onSubmit}
                                        initialValues={initialValue}
                                        btnText={"Update"}
                                        renderCustomSubmit={
                                            <>
                                                <ImagePicker onImageSelect={this.handleImage} />
                                                {/* <ImageInput img={image} onSelectImg={this.handleImage} /> */}
                                                <SelectAirports addedAirports={airports} handleAirports={this.handleAirports} />
                                                <RelatedTags relatedTags={this.state.relatedTags} handleRelatedTags={this.handleRelatedTags} />
                                                <TagLevel value={this.state.levelValue} onLevelChange={this.onLevelChange} />
                                                <div className="bottomSection">
                                                    <Button onClick={this.onCancel} btnText={"Cancel"} variant={"contained"} />
                                                    <Button onClick={() => { }} btnText={"Update"} variant={"contained"} />
                                                </div>
                                            </>
                                        }
                                    />

                                </div>
                            </div>
                        </RightPannel>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(EditTags);