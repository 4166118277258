import Axios from "axios";
import auth from "../../auth";
import { ResponseAPI, TagParams } from "../type";
import { API_URL, handleError } from "../utils";


const getAirports = async (q: string): Promise<ResponseAPI> => {
    try {
        let resp = await Axios({
            method: "POST",
            url: `${API_URL}/airports/search`,
            data: {
                "search": q,
                "status": "true"
            }
        })
        return { status: true, data: resp.data };
    } catch (error) {
        return handleError(error)
    }
}

const addTagsAPI = async (data: TagParams, token: string): Promise<ResponseAPI> => {
    try {
        let resp = await Axios({
            method: "POST",
            data,
            url: `${API_URL}/tags/createtag`,
            headers: {
                Authorization: token
            }
        })
        return { status: true, data: resp.data };
    } catch (error) {
        return handleError(error)
    }
}

const updateTagAPI = async (id: string, data: TagParams, token: string): Promise<ResponseAPI> => {
    try {
        let resp = await Axios({
            method: "PUT",
            data,
            url: `${API_URL}/tags/updatetag/${id}`,
            headers: {
                Authorization: token
            }
        })
        return { status: true, data: resp.data };
    } catch (error) {
        return handleError(error)
    }
}
const getTagsAPI = async (): Promise<ResponseAPI> => {
    try {
        let resp = await Axios({
            method: "GET",
            url: `${API_URL}/tags`,
        })
        return { status: true, data: resp.data };
    } catch (error) {
        return handleError(error)
    }
}

const getTagsByIdAPI = async (id: string): Promise<ResponseAPI> => {
    try {
        let resp = await Axios({
            method: "GET",
            url: `${API_URL}/tags/${id}`,
        })
        return { status: true, data: resp.data };
    } catch (error) {
        return handleError(error)
    }
}

const deleteTagAPI = async (id: string, token: string): Promise<ResponseAPI> => {
    try {
        let resp = await Axios({
            method: "DELETE",
            url: `${API_URL}/tags/delete/${id}`,
            headers: {
                Authorization: token
            }
        })
        return { status: true, data: resp.data };
    } catch (error) {
        return handleError(error)
    }
}
const searchTagsAPIs = async (value: string): Promise<ResponseAPI> => {
    try {
        let resp = await Axios({
            method: "GET",
            url: `${API_URL}/tags/search/${value}`,
            headers: {
                Authorization: auth.getToken()
            }
        })
        return { status: true, data: resp.data };
    } catch (error) {
        return handleError(error)
    }
}


export {
    addTagsAPI,
    getAirports,
    getTagsAPI,
    getTagsByIdAPI,
    deleteTagAPI,
    updateTagAPI,
    searchTagsAPIs
}