import React, { Component } from "react";
import "./style.scss";
import { Button } from "@material-ui/core"
import { ArrowBack } from "@material-ui/icons"
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import Loader from "../../../components/loader";
import Trips, { FlightDetails, ItinerarieType } from "../../../components/trips";
import { FLIGHTS } from "./utils";
import { UserBookingType } from "../booking";
import { CancelFlight } from "../booking/utils";


class UserTrip extends Component<RouteComponentProps, {
    loading: boolean;
    flights: ItinerarieType[];
    pnr: string;
    book: UserBookingType | null;
}> {
    state = {
        loading: false,
        flights: [],
        pnr: "",
        book: null,
    }

    async componentDidMount() {
        const { state }: any = this.props.location;
        if (state && state.flightDetail)
            this.setState({ book: state, pnr: JSON.parse(state.associatedRecords)[0].reference, flights: JSON.parse(state.flightDetail)[0].itineraries })
        else this.handleGoBack();
    }
    handleGoBack = () => {
        const { id }: any = this.props.match.params;
        if (id) {
            this.props.history.push("/users/booking/" + id)
        }
        else this.props.history.push("/users");
    }

    render() {
        const { flights, loading, pnr } = this.state;
        const book: any = this.state.book;
        return (
            <div className="root-container">
                <Loader show={loading} />

                <div className="trip-root-container">
                    <div className="btn-container">
                        <Button
                            className="back-btn"
                            onClick={this.handleGoBack}
                        >
                            <ArrowBack /> Back
				        </Button>
                        {book && book?.bookingStatus === "Active" ?
                            <CancelFlight book={book} updateTrips={this.handleGoBack} />
                            : null}
                    </div>

                    {flights && flights.length > 1 &&
                        <div>
                            <h4 id="transition-modal-title">Booking Details</h4>
                            <Trips flight={flights} pnr={pnr} />
                            <FlightDetails flights={flights} />
                        </div>}
                </div>
            </div >
        );
    }
}

export default withRouter(UserTrip);