import React from "react";
import { style } from "./style";
import Button from "../button";
import { useSpring } from "@react-spring/core";
import { animated } from "@react-spring/web";

interface Props {
  // classes: any;
  header: string;
  message: string;
  buttonText: string;
  buttonType: string;
  onClick: () => void;
  children: React.ReactNode;
  from: number;
  txtFrom: number;
}

const Banner: React.FC<Props> = ({
  header,
  message,
  buttonText,
  buttonType,
  onClick,
  children,
  from,
  txtFrom
}) => {
  const styles = useSpring({
    from: { x: from },
    config: { duration: 500 },
    loop: {
      x: 0,
    },
  })
  const contentStyles = useSpring({
    from: { x: txtFrom },
    config: { duration: 500 },
    loop: {
      x: 0,
    },
  })
  const classes = style();
  return (
    <animated.div
      style={styles}
      className={classes.animationContainer}
    >
      <div className={classes.root}>
        {children}
        <animated.div style={contentStyles} className={classes.container}>
          <div className={classes.mBottom}>
            <div className={classes.header}>{header}</div>
            <div className={classes.message}>{message}</div>
          </div>
          {buttonText && <Button btnText={buttonText} onClick={onClick} variant={buttonType} />}
        </animated.div>
      </div>
    </animated.div>

  );
};

export default Banner;
