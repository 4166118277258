import { AwsCognitoConfig } from "./types";
export const awsCognito: AwsCognitoConfig = {
  region: process.env.REACT_APP_COGNITO_REGION || "",
  Auth: {
    userPoolId: process.env.REACT_APP_UserPool || "",
    userPoolWebClientId: process.env.REACT_APP_ClientId || "",
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL || "",
    authenticationFlowType: "USER_PASSWORD_AUTH",
    cookieStorage: {
      domain: process.env.REACT_APP_domain || "",
      path: "/",
      expires: 7,
      secure: process.env.NODE_ENV === "production",
    },
  },
};
