import { LocalOffer, Public, Description, AttachMoney, Today, FlightTakeoff, AirlineSeatReclineExtra } from "@material-ui/icons";
import CountryField from "../../components/FormComponents/country";
import { field } from "../../components/FormComponents/types";

const fields: field[] = [
    {
        name: "name",
        placeholder: "Plan Name",
        type: "text",
        icon: <LocalOffer />,
        required: true,
        fieldType: "tripStarter",
        variant: "tripStarter",
    },
    {
        name: "status",
        labelText: "Status",
        placeholder: "Hide/Show",
        type: "radioButton",
        options: [
            { label: "Show", id: "true" },
            { label: "Hide", id: "false" }
        ],
        required: true,
        value: "true",
        fieldType: "tripStarter",
        variant: "tripStarter",
    },
    {
        name: "price",
        placeholder: "Plan price",
        type: "number",
        icon: <AttachMoney />,
        required: true,
        fieldType: "tripStarter",
        variant: "tripStarter",
        min: 1,
        max: 99999999
    },
    {
        name: "month",
        placeholder: "No. of Month",
        type: "number",
        required: true,
        icon: <Today />,
        fieldType: "tripStarter",
        variant: "tripStarter",
        max: 60,
        min: 1
    },
    {
        name: "flight_class",
        placeholder: "Flight class",
        type: "select",
        options: [
            {
                id: "ECONOMY",
                label: "ECONOMY",
            },
            {
                id: "FIRST",
                label: "FIRST",
            },
            {
                id: "BUSINESS",
                label: "BUSINESS",
            }
        ],
        icon: <AirlineSeatReclineExtra />,
        fieldType: "tripStarter",
        variant: "tripStarter",
    },
    {
        name: "flights",
        placeholder: "No. of flights",
        type: "number",
        icon: <FlightTakeoff />,
        fieldType: "tripStarter",
        variant: "tripStarter",
    },
    {
        name: "country",
        placeholder: "Country",
        type: "text",
        icon: <Public />,
        required: true,
        fieldType: "tripStarter",
        component: CountryField,
        variant: "tripStarter",
    },
    {
        name: "description",
        placeholder: "Description",
        type: "text",
        icon: <Description />,
        required: true,
        minRows: 3,
        rows: 3,
        multiline: true,
        fieldType: "tripStarter",
        variant: "tripStarter",
        max: 255,
        min: 10
    },
];


export {
    fields,
}