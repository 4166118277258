import React, { Component } from "react";
import DataTable from "../../components/table";
import { HeadCell } from "../../components/table/type";
import { ArrowBack } from "@material-ui/icons";
import "./style.scss";
import { withRouter } from "react-router-dom";
import { Button } from "@material-ui/core";
import { AirportType } from "../../components/select-airports";
import Loader from "../../components/loader";
import { getTagByIdFromDB } from "../../indexedDB/tags";
import { notifError } from "../util";
import { getObjectsFromIndexDB } from "../../components/select-airports/filter-airports";

const headCells: HeadCell[] = [
	{ name: "name", label: "Name" },
	{ name: "iataCode", label: "Airport Code" },
	{ name: "city", label: "City" },
	{ name: "countryCode", label: "Country" },
];

class Airports extends Component<
	any,
	{
		airports: AirportType[];
		loading: boolean;
		name: string;
		img: string;
	}
> {
	state = {
		airports: [],
		loading: true,
		name: "",
		img: ""
	};

	async componentDidMount() {
		let res = await getTagByIdFromDB(this.props.match.params.name)
		if (res) {
			const { image = "", name = "", airids = [] }: any = res;
			let airports = await getObjectsFromIndexDB("airports", JSON.parse(airids) || [])
			this.setState({ name, img: image, airports, loading: false })
		} else {
			this.setState({ loading: false });
			notifError("Tags", "something went wrong.")
		}
	}

	render() {
		const { airports, loading, img, name } = this.state;

		return (
			<div className="root-container">
				<Loader show={loading} />
				<Button
					className="back-btn"
					onClick={() => {
						this.props.history.push("/tags");
					}}
				>
					<ArrowBack /> Back
				</Button>
				<DataTable
					title={"Airports"}
					headers={headCells}
					data={airports}
				>
					<div id="airports">
						<h3>
							<span>Tag</span>: <b className="tag-name">{name}</b>
						</h3>
						<img src={img} alt="" />
					</div>
				</DataTable>
			</div>
		);
	}
}

export default withRouter(Airports);
