import Axios from "axios";
import { ResponseAPI } from "./type";
import { API_URL, handleError } from "./utils"


const profileAPI = async (token: string): Promise<ResponseAPI> => {
    try {
        let resp = await Axios({
            method: "GET",
            url: API_URL + "/user/profile/admin",
            headers: {
                Authorization: token
            }
        })
        return { status: true, data: resp.data };
    } catch (error) {
        return handleError(error)
    }
}

export {
    profileAPI
}