import React, { Component } from "react";
import DataTable from "../../../components/table";
import { HeadCell } from "../../../components/table/type";
import "./style.scss";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import Loader from "../../../components/loader";
import { parsePlans } from "./utils";
import { notifError, notifSuccess } from "../../util";
import { getUserPlanAPIs, updateUserPlanAPIs } from "../../../services/api-services/users";
import { Button } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

const headCells: HeadCell[] = [
    { name: 'plan_id', label: 'Plan Id' },
    { name: 'paymentId', label: 'Payment Id' },
    { name: 'flights', label: 'No. Of Flights' },
    { name: 'expiryDate', label: 'Expiry Date' },
    { name: 'status', label: 'Status' },
    { name: 'action', label: 'Action' },
];
export type UserPlanType = {
    createdAt: null | string;
    updatedAt: string;
    id: number;
    expiryDate: string;
    status: boolean | any;
    currency: string;
    paymentId: string;
    flights: number | string;
    user_id: number | string;
    plan_id: number | string;
    action?: any;
}


class UserPlans extends Component<RouteComponentProps, {
    loading: boolean;
    plans: UserPlanType[];
}> {
    state = {
        plans: [],
        loading: false
    }

    async componentDidMount() {
        this.setState({ loading: true })
        const { id }: any = this.props.match.params;
        let res = await getUserPlanAPIs(id)
        if (res.status)
            this.setState({ loading: false, plans: parsePlans(res.data, this.updatePlans, this.props.history) })
        else {
            notifError("Tags", "something went wrong.")
            this.setState({ loading: false })
        }
    }
    updatePlans = async (id: number | string) => {
        let { plans }: { plans: UserPlanType[] } = this.state;
        this.setState({ loading: true, plans: [] })
        let res = await updateUserPlanAPIs(id);
        if (res.status && res.data) {
            const plns: UserPlanType[] = plans.filter((item: any) => item.id.toString() !== id.toString());
            notifSuccess("Plans", 'Plan has been updated successfully.')
            plans = parsePlans([res.data], this.updatePlans, this.props.history)
            this.setState({ plans: [...plns, ...plans], loading: false })
        }
        else {
            this.setState({ loading: false })
            notifError("Plans", "something went wrong")
        }
    };
    render() {
        const { plans, loading } = this.state;
        return (
            <div className="root-container">
                <Loader show={loading} />
                <Button
                    className="back-btn"
                    onClick={() => {
                        this.props.history.push("/users");
                    }}
                >
                    <ArrowBack /> Back
				</Button>
                <DataTable
                    title={"Plans"}
                    headers={headCells}
                    data={plans}
                >
                    <></>
                </DataTable>
            </div>
        );
    }
}

export default withRouter(UserPlans);