import React, { useEffect } from "react";
import clsx from "clsx";
import { Theme, makeStyles } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import NavContent from "./NavContent";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { themeProps } from "./type";
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

const drawer_width = 228;

interface Style {
  backgroudColor: string;
  fontSize: string;
  IconColor: string;
  fontColor: string;
  drawerWidth: number;
  closeDrawerWidth: number;
  selectedMenuColor: string;
}

const styles = makeStyles<Theme, Style>((theme) => ({
  color: {
    color: props => props.fontColor,
  },
  topDrawer: {
    backgroundColor: props => props.backgroudColor,
    color: props => props.fontColor,
    "@media (min-width:800px)": {
      display: "none",
    },
  },
  drawer: {
    width: (props) => (props.drawerWidth ? props.drawerWidth : drawer_width),
    flexShrink: 0,
    whiteSpace: "nowrap",
    overflowX: "hidden",
    "@media (max-width:800px)": {
      width: "0px !important;",
    },
  },
  drawerOpen: {
    width: (props) => (props.drawerWidth ? props.drawerWidth : drawer_width),
    overflowX: "hidden",
    transition: theme.transitions.create(["width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    overflowX: "hidden",
    width: (props) => (props.closeDrawerWidth ? props.closeDrawerWidth : 60),
    transition: theme.transitions.create(["width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "@media (max-width:800px)": {
      width: "0px !important;",
    },
  },
  root: {
    display: "flex",
  },
})) as any;

interface Children {
  title?: string;
  to?: string;
  component?: () => JSX.Element;
}

interface MenuItems {
  title?: string;
  icon_type?: string;
  to?: string;
  component?: () => JSX.Element;
  children?: Children[];
}

interface MenuProps {
  menu?: MenuItems[];
  className: Style;
  theme: themeProps;
}

const NavDrawer: React.FC<MenuProps> = ({ menu, className, theme }) => {
  const classes = styles(className);
  const { root, drawer, drawerClose, drawerOpen } = classes;
  const [isOpen, setOpen] = React.useState(false);
  function toggleDrawer() {
    setOpen(!isOpen);
  }
  return (
    <Router>
      <div className={root}>
        <AppBar position="fixed" className={classes.topDrawer}>
          <Toolbar>
            <IconButton
              className={classes.color}
              onClick={toggleDrawer}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(drawer, {
            [drawerOpen]: isOpen,
            [drawerClose]: !isOpen,
          })}
          classes={{
            paper: clsx({
              [drawerOpen]: isOpen,
              [drawerClose]: !isOpen,
            }),
          }}
        >
          <NavContent
            className={className}
            menu={menu}
            isOpen={isOpen}
            theme={theme}
            toggleDrawer={toggleDrawer}
          />
        </Drawer>
      </div>
      <Switch>
        {menu &&
          menu.map((route) =>
            route.children ? (
              route.children.map((child) => (
                <Route exact path={child.to} component={child.component} />
              ))
            ) : (
              <Route exact path={route.to} component={route.component} />
            )
          )}
      </Switch>
    </Router>
  );
};

export default React.memo(NavDrawer);
