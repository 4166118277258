import React, { useEffect, useState } from "react";
import "./index.scss";
import { TextField, InputAdornment, FormControl } from "@material-ui/core";

interface Form {
    errors: Object;
    touched: Object;
}

interface Props {
    icon?: any;
    variant: string;
    className: string;
    inline: boolean;
    labelText: string;
    labelPositionLeft: Boolean;
    labelCss: string;
    form: any;
    field: any;
    value: string | number;
    type?: string;
    placeholder: string;
    create_err_msg?: any;
}

const iconComponentProps = (
    icon: any
): any => ({
    InputProps: {
        startAdornment: icon ? (
            <InputAdornment
                position="start"
                className="visibility-icon"
            >
                {icon}
            </InputAdornment>
        ) : null
    }
});


const FullName: React.FC<Props> = React.memo(
    ({
        icon,
        variant,
        className,
        placeholder,
        labelCss,
        labelPositionLeft,
        form,
        field,
        ...rest
    }) => {
        const [values, setValues] = useState<any>({});
        const variantCss =
            variant === "tripStarter"
                ? "primary"
                : variant === "Outlined"
                    ? "outlined"
                    : variant === "Dark"
                        ? "dark-outlined"
                        : "outlined";
        useEffect(() => {
            if (field.value && typeof (field.value) === "string") {
                const vls = field.value.split(" ");
                const data: any = {};
                placeholder.split(" | ").forEach((item: string, index: number) => {
                    data[item] = vls[index] || ""
                })
                form.setFieldValue(field.name, data)
                setValues(data)
            }

        }, [field.value])
        const handleChange = ({ target: { name, value } }: any) => {
            values[name] = value;
            setValues(values);
            form.setFieldValue(field.name, values)
        }
        return (
            <>
                <div className={labelPositionLeft && "flex-box-input"}>
                    <div className={`label-text ${labelCss}`}>{rest.labelText}</div>
                    <div className={`${variantCss} ${className}`} style={{ display: "flex" }}>
                        {placeholder.split(" | ").map((item, index) => (
                            <TextField
                                key={index}
                                fullWidth
                                className={index > 0 && "left-bdr"}
                                value={values[item]}
                                {...(index === 0 ? iconComponentProps(icon) : {})}
                                type={"text"}
                                placeholder={item}
                                name={item}
                                // value={field.value ? field.value[item] : ""}
                                onChange={handleChange}
                            />
                        ))}
                    </div>
                </div>
                {form && form.errors[field.name] && form.touched[field.name] && (
                    <div className="error-text">
                        {rest.create_err_msg
                            ? rest.create_err_msg(field.value)
                            : form.errors[field.name]}
                        {rest.create_err_msg && field.name === "cpassword" && (
                            <p>{form.errors[field.name]}</p>
                        )}
                    </div>
                )}
            </>
        );
    }
);

export default FullName;
