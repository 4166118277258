import { UserBookingType } from ".";
import { ActionPara, Data } from "../../../components/table/type";
import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import "./style.scss";
import { Close, Visibility } from "@material-ui/icons";
import Trips, { FlightDetails, ItinerarieType } from "../../../components/trips";
import DialogBox from "../../../components/dialog-box";
import { cancelFlightAPIs } from "../../../services/api-services/users";
import { notifError, notifSuccess } from "../../util";
import Loader from "../../../components/loader";
import Button from "../../../components/button";

const FLIGHTS: ItinerarieType[] = [
	{
		"duration": "PT2H10M",
		"segments": [
			{
				"departure": {
					"iataCode": "DEL",
					"terminal": "3",
					"at": "2021-10-09T08:00:00"
				},
				"arrival": {
					"iataCode": "BOM",
					"terminal": "2",
					"at": "2021-10-09T10:10:00"
				},
				"carrierCode": "AI",
				"number": "665",
				"aircraft": {
					"code": "321"
				},
				"duration": "PT2H10M",
				"id": "1",
				"numberOfStops": 0,
				"blacklistedInEU": false
			},
			{
				"departure": {
					"iataCode": "DEL",
					"terminal": "3",
					"at": "2021-10-09T08:00:00"
				},
				"arrival": {
					"iataCode": "BOM",
					"terminal": "2",
					"at": "2021-10-09T10:10:00"
				},
				"carrierCode": "AI",
				"number": "665",
				"aircraft": {
					"code": "321"
				},
				"duration": "PT2H10M",
				"id": "1",
				"numberOfStops": 0,
				"blacklistedInEU": false
			},
			{
				"departure": {
					"iataCode": "DEL",
					"terminal": "3",
					"at": "2021-10-09T08:00:00"
				},
				"arrival": {
					"iataCode": "BOM",
					"terminal": "2",
					"at": "2021-10-09T10:10:00"
				},
				"carrierCode": "AI",
				"number": "665",
				"aircraft": {
					"code": "321"
				},
				"duration": "PT2H10M",
				"id": "1",
				"numberOfStops": 0,
				"blacklistedInEU": false
			}
		]
	},
	{
		"duration": "PT2H10M",
		"segments": [
			{
				"departure": {
					"iataCode": "BOM",
					"terminal": "2",
					"at": "2021-10-16T07:00:00"
				},
				"arrival": {
					"iataCode": "DEL",
					"terminal": "3",
					"at": "2021-10-16T09:10:00"
				},
				"carrierCode": "AI",
				"number": "864",
				"aircraft": {
					"code": "788"
				},
				"duration": "PT2H10M",
				"id": "7",
				"numberOfStops": 0,
				"blacklistedInEU": false
			}, {
				"departure": {
					"iataCode": "BOM",
					"terminal": "2",
					"at": "2021-10-16T07:00:00"
				},
				"arrival": {
					"iataCode": "DEL",
					"terminal": "3",
					"at": "2021-10-16T09:10:00"
				},
				"carrierCode": "AI",
				"number": "864",
				"aircraft": {
					"code": "788"
				},
				"duration": "PT2H10M",
				"id": "7",
				"numberOfStops": 0,
				"blacklistedInEU": false
			}, {
				"departure": {
					"iataCode": "BOM",
					"terminal": "2",
					"at": "2021-10-16T07:00:00"
				},
				"arrival": {
					"iataCode": "DEL",
					"terminal": "3",
					"at": "2021-10-16T09:10:00"
				},
				"carrierCode": "AI",
				"number": "864",
				"aircraft": {
					"code": "788"
				},
				"duration": "PT2H10M",
				"id": "7",
				"numberOfStops": 0,
				"blacklistedInEU": false
			}
		]
	}
]

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: '0px solid #000',
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
	}),
);

export const CancelFlight: React.FC<{ book: UserBookingType; updateTrips: (id: string) => void }> = ({ book, updateTrips }) => {

	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleAccept = async (sts: boolean | undefined) => {
		setOpen(false);
		if (sts) {
			setLoading(true);
			const { bookingId = "" } = book;
			const resp = await cancelFlightAPIs({ bookingId });
			console.log("resp", resp)
			if (resp.status) {
				notifSuccess("Flight", resp.data?.errorMessage || "Done");
				updateTrips(bookingId);
			}
			else
				notifError("Flight", resp.data?.errorMessage.replace("[404]", "") || "something went wrong.")
			setLoading(false);
		}
	};
	return (
		<div>
			<Loader show={loading} />
			<Button className="t-btn cancel-flight-btn" onClick={handleOpen} btnText={"Cancel"} variant="contained" />
			<DialogBox
				open={open}
				btnTxt={{ cancel: "No", accept: "Yes" }}
				title={`Cancel Flight`}
				des={`Are you sure you want to cancel the flight?`}
				handleAccept={handleAccept}
			/>
		</div>
	);
}


const getActions = (history: any, book: UserBookingType, updateTrips: (id: string) => void): ActionPara[] => {
	const actions: ActionPara[] = [
		{
			name: "Trip",
			action: () => history.push({
				pathname: `/users/booking/${book.user_id}/trip/${book.bookingId}`,
				state: book
			})
		}
	];
	if (book.bookingStatus === "Active") {
		actions.push({
			name: "Cancel",
			component: <CancelFlight updateTrips={updateTrips} book={book} />
		})
	}
	return actions;
}

const parseBooking = (plans: UserBookingType[], history: any, updateTrips: (id: string) => void): UserBookingType[] => {
	let parse = plans.map(
		(book: UserBookingType) => ({
			...book,
			action: getActions(history, book, updateTrips),
		})
	);
	return parse;
};

export { parseBooking };
