import { checkDBExists, getByIdFromDB, saveDataWithDB, getAllFromDB, deleteByIdFromDB, updateByIdFromDB, addNewDataInDB } from ".";
import { getTagsAPI } from "../services/api-services/tags";

async function checkAndSaveDataInDB() {
    let isDBExists = await checkDBExists("tags");
    if (!isDBExists) {
        let resp = await getTagsAPI()
        if (resp.status && Array.isArray(resp.data)) {
            let data: any = resp.data;
            await saveDataWithDB("tags", data)

        }
    }
    return true
}

const getTagByIdFromDB = async (id: number) => {
    try {
        await checkAndSaveDataInDB()
        let res = await getByIdFromDB("tags", id);
        return res
    } catch (err) {
        console.log("-----getTagById------", err)
        return null
    }
}

const getAllTagsFromDB = async () => {
    try {
        await checkAndSaveDataInDB()
        let res = await getAllFromDB("tags");
        console.log("resp", res)
        return res
    } catch (err) {
        console.log("-----getAllTagsFromDB------", err)
        return null
    }

}
const deleteTagsFromDB = async (id: number) => {
    try {
        await checkAndSaveDataInDB()
        let res = await deleteByIdFromDB("tags", id);
        console.log("resp", res)
        return true
    } catch (err) {
        console.log("-----deleteTagsFromDB------", err)
        return false
    }
}
const updateTagFromDB = async (id: number, data: any) => {
    try {
        await checkAndSaveDataInDB()
        let res = await updateByIdFromDB("tags", id, data);
        console.log("resp", res)
        return true
    } catch (err) {
        console.log("-----updateTagFromDB------", err)
        return false
    }
}

const addNewTagInDB = async (data: any) => {
    try {
        await checkAndSaveDataInDB()
        let res = await addNewDataInDB("tags", data);
        console.log("resp", res)
        return true
    } catch (err) {
        console.log("-----addNewTagInDB------", err)
        return false
    }
}
export {
    getTagByIdFromDB,
    getAllTagsFromDB,
    deleteTagsFromDB,
    updateTagFromDB,
    addNewTagInDB
}