import auth from "../auth";
import { ResponseAPI } from "./type";

const API_URL = process.env.REACT_APP_APIS || "";

const handleError = (err: any): ResponseAPI => {
    if (err && err.response && err.response.data) {
        if (err.response.data?.errorCode === 403) {
            auth.logout();
            window.location.href = "/"
        }
        return { status: false, data: err.response.data }
    }
    else {
        return { status: false, data: err }
    }
}
export {
    API_URL,
    handleError
}