import { HttpsOutlined, PermIdentity, MailOutline } from "@material-ui/icons";
import { password_conditions } from "../../components/FormComponents/utils";
import { verifyPassword } from "../util";

const signInFields = [
    {
        name: "email",
        placeholder: "Email",
        type: "email",
        icon: <MailOutline />,
        required: true,
        fieldType: "tripStarter",
        variant: "tripStarter",
    },
    {
        name: "password",
        placeholder: "Password",
        type: "password",
        required: true,
        icon: <HttpsOutlined />,
        displayable: true,
        fieldType: "tripStarter",
        variant: "tripStarter",
		matches: password_conditions,
        create_err_msg: verifyPassword,

    },
];


export {
    signInFields,
}