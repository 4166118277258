import Axios from "axios";
import auth from "../../auth";
import { ResponseAPI, TagParams } from "../type";
import { API_URL, handleError } from "../utils";


const getAllUserAPIs = async (page: number, perPage: number): Promise<ResponseAPI> => {
    try {
        let resp = await Axios({
            method: "GET",
            url: `${API_URL}/user/admin/all/${page}/${perPage}`,
            headers: {
                Authorization: auth.getToken()
            }
        })
        return { status: true, data: resp.data };
    } catch (error) {
        return handleError(error)
    }
}

const getTravellersAPIs = async (id: string): Promise<ResponseAPI> => {
    try {
        let resp = await Axios({
            method: "GET",
            url: `${API_URL}/user/admin/travellers/${id}`,
            headers: {
                Authorization: auth.getToken()
            }
        })
        return { status: true, data: resp.data };
    } catch (error) {
        return handleError(error)
    }
}

const updateTravellerAPIs = async (data: any): Promise<ResponseAPI> => {

    try {
        let resp = await Axios({
            method: "PUT",
            url: `${API_URL}/user/admin/traveller/update`,
            data,
            headers: {
                Authorization: auth.getToken()
            }
        })
        return { status: true, data: resp.data };
    } catch (error) {
        return handleError(error)
    }
}

const searchUserAPIs = async (value: string): Promise<ResponseAPI> => {

    try {
        let resp = await Axios({
            method: "GET",
            url: `${API_URL}/user/admin/search/${value}`,
            headers: {
                Authorization: auth.getToken()
            }
        })
        return { status: true, data: resp.data };
    } catch (error) {
        return handleError(error)
    }
}

const getUserPlanAPIs = async (id: string): Promise<ResponseAPI> => {
    try {
        let resp = await Axios({
            method: "GET",
            url: `${API_URL}/payments/user/${id}`,
            headers: {
                Authorization: auth.getToken()
            }
        })
        return { status: true, data: resp.data };
    } catch (error) {
        return handleError(error)
    }
}

const updateUserPlanAPIs = async (id: number | string): Promise<ResponseAPI> => {
    try {
        let resp = await Axios({
            method: "PUT",
            url: `${API_URL}/payments/user/plan/${id}`,
            headers: {
                Authorization: auth.getToken()
            }
        })
        return { status: true, data: resp.data };
    } catch (error) {
        return handleError(error)
    }
}

const getUserBookingAPIs = async (id: number | string): Promise<ResponseAPI> => {
    try {
        let resp = await Axios({
            method: "GET",
            url: `${API_URL}/booking/flight/user/${id}`,
            headers: {
                Authorization: auth.getToken()
            }
        })
        return { status: true, data: resp.data };
    } catch (error) {
        return handleError(error)
    }
}
type CancelFlightTypeReq = {
    bookingId: string;
}

const cancelFlightAPIs = async (data: CancelFlightTypeReq): Promise<ResponseAPI> => {
    try {
        let resp = await Axios({
            method: "PUT",
            data,
            url: `${API_URL}/booking/cancel/admin`,
            headers: {
                Authorization: auth.getToken()
            }
        })
        return { status: true, data: resp.data };
    } catch (error) {
        return handleError(error)
    }
};

export {
    getAllUserAPIs,
    getTravellersAPIs,
    updateTravellerAPIs,
    searchUserAPIs,
    getUserPlanAPIs,
    updateUserPlanAPIs,
    getUserBookingAPIs,
    cancelFlightAPIs
}