const expiryTime = 2 // hours

const validateCreatedTime = (time: string): boolean => {
    var date1 = new Date(time);
    var date2 = new Date();
    const milliseconds = Math.abs(date2.valueOf() - date1.valueOf());
    const hours = milliseconds / 36e5;
    return hours < expiryTime;
}

const checkDBExists = async (dbName: string, validate = true): Promise<Boolean> => {
    return new Promise((resolve, rejects) => {
        if (indexedDB) {
            var req = indexedDB.open(dbName);
            var existed = true;
            req.onsuccess = function (e: any) {
                var db = e.target.result;
                if (!existed || !db.objectStoreNames.contains(dbName)) {
                    req.result.close();
                    indexedDB.deleteDatabase(dbName);
                    resolve(false)
                } else if (validate) {
                    var trans = db.transaction([dbName], 'readwrite'); //second step is opening the object store
                    var store = trans.objectStore(dbName);
                    var request = store.openCursor();
                    request.onsuccess = function (e: any) {
                        var cursor = request.result;
                        if (cursor && validateCreatedTime(cursor.value.createdOn)) {
                            req.result.close();
                            resolve(true)
                        }
                        else {
                            req.result.close();
                            indexedDB.deleteDatabase(dbName);
                            resolve(false)
                        }

                    }
                }
                req.result.close();
                resolve(true)
            }
            req.onupgradeneeded = function () {
                existed = false;
            }
            req.onerror = function () {
                resolve(false)
            }
        } else {
            console.log("Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available.");
            rejects(false)
        }
    })
}
const saveDataWithDB = async (dbName: string, data: []): Promise<Boolean> => {
    return new Promise((resolve, rejects) => {
        if (indexedDB) {
            const request = indexedDB.open(dbName);
            request.onupgradeneeded = function () {
                console.log("++++++++++onupgradeneeded++++++")
                const db = request.result;
                const store = db.createObjectStore(dbName, { keyPath: "id" });
                data.forEach((item: any) => {
                    store.put({ ...item, createdOn: new Date() });
                });
                resolve(true)
            };
            request.onsuccess = function (e: any) {
                console.log("-----onsuccess----")
            }

            request.onerror = function () {
                rejects(false)
            }
        } else {
            console.log("Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available.");
            rejects(false)
        }
    })
}

const getByIdFromDB = (dbName: string, id: number) => {
    return new Promise((resolve, rejects) => {
        if (indexedDB) {
            var request = indexedDB.open(dbName); // first step is opening the database
            request.onsuccess = function (e: any) {
                var db = e.target.result;
                if (db.objectStoreNames.contains(dbName)) {
                    var trans = db.transaction([dbName], 'readwrite'); //second step is opening the object store
                    var store = trans.objectStore(dbName);
                    var request = store.get(Number(id)); //getting single object by id from object store
                    request.onsuccess = function (e: any) {
                        var cursor = request.result;
                        if (cursor)
                            resolve(cursor)
                        else
                            rejects(null)


                    }
                    request.onerror = function (e: any) {
                        rejects(null);
                    };

                } else rejects(null)

            };
            request.onerror = function (e: any) {
                rejects(null);
            };

        }
    })
}

const getAllFromDB = (dbName: string) => {
    return new Promise((resolve, rejects) => {
        if (indexedDB) {
            var request = indexedDB.open(dbName); // first step is opening the database
            request.onsuccess = function (e: any) {
                var db = e.target.result;
                if (db.objectStoreNames.contains(dbName)) {
                    var trans = db.transaction([dbName], 'readwrite'); //second step is opening the object store
                    var store = trans.objectStore(dbName);
                    var request = store.getAll(); //getting single object by id from object store
                    request.onsuccess = function (e: any) {
                        var cursor = request.result;
                        if (cursor)
                            resolve(cursor)
                        else
                            rejects(null)


                    }
                    request.onerror = function (e: any) {
                        rejects(null);
                    };

                } else rejects(null)

            };
            request.onerror = function (e: any) {
                rejects(null);
            };

        }
    })
}

const deleteByIdFromDB = (dbName: string, id: number) => {
    return new Promise((resolve, rejects) => {
        if (indexedDB) {
            var request = indexedDB.open(dbName); // first step is opening the database
            request.onsuccess = function (e: any) {
                var db = e.target.result;
                if (db.objectStoreNames.contains(dbName)) {
                    var trans = db.transaction([dbName], 'readwrite'); //second step is opening the object store
                    var store = trans.objectStore(dbName);
                    var request = store.delete(Number(id)); //getting single object by id from object store
                    request.onsuccess = function (e: any) {
                        resolve(true)
                    }
                    request.onerror = function (e: any) {
                        rejects(false);
                    };
                } else rejects(false)
            };
            request.onerror = function (e: any) {
                rejects(false);
            };

        }
    })
}

const updateByIdFromDB = (dbName: string, id: number, data: any) => {
    return new Promise((resolve, rejects) => {
        if (indexedDB) {
            var request = indexedDB.open(dbName); // first step is opening the database
            request.onsuccess = function (e: any) {
                var db = e.target.result;
                if (db.objectStoreNames.contains(dbName)) {
                    var trans = db.transaction([dbName], 'readwrite'); //second step is opening the object store
                    var store = trans.objectStore(dbName);
                    var request = store.put({ ...data, createdOn: new Date() }, Number(id)); //getting single object by id from object store
                    request.onsuccess = function (e: any) {
                        resolve(true)
                    }
                    request.onerror = function (e: any) {
                        rejects(false);
                    };
                } else rejects(false)
            };
            request.onerror = function (e: any) {
                rejects(false);
            };

        }
    })
}

const addNewDataInDB = (dbName: string, data: any) => {
    return new Promise((resolve, rejects) => {
        if (indexedDB) {
            var request = indexedDB.open(dbName); // first step is opening the database
            request.onsuccess = function (e: any) {
                var db = e.target.result;
                if (db.objectStoreNames.contains(dbName)) {
                    var trans = db.transaction([dbName], 'readwrite'); //second step is opening the object store
                    var store = trans.objectStore(dbName);
                    var request = store.put({ ...data, createdOn: new Date() }); //getting single object by id from object store
                    request.onsuccess = function (e: any) {
                        resolve(true)
                    }
                    request.onerror = function (e: any) {
                        rejects(false);
                    };
                } else rejects(false)
            };
            request.onerror = function (e: any) {
                rejects(false);
            };

        }
    })
}

export {
    checkDBExists,
    saveDataWithDB,
    getByIdFromDB,
    getAllFromDB,
    deleteByIdFromDB,
    updateByIdFromDB,
    addNewDataInDB
}