import { makeStyles, createStyles } from "@material-ui/core/styles";

export const style = makeStyles(() => createStyles({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    background: "#c29524",

    boxShadow: "10px 0px 20px rgba(21, 39, 75, 0.25)",
    position: "relative",
    height: "100%",

  },
  animationContainer: {
    width: "40%",
    overflow: "hidden",
    "@media (max-width:800px)": {
      display: "none",
    },
  },
  header: {
    color: "rgba(21, 39, 75, 1)",
    fontSize: 32,
  },
  message: {
    fontSize: 13,
    marginTop: 25,
    maxWidth: 179,
    textAlign: "center",
  },
  container: {
    display: "flex",
    height: "70%",
    maxHeight: "100%",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "85%",
    textAlign: "center",
    flexDirection: "column",
  },
  mBottom: {
    marginBottom: 80
  },
  spot1: {
    position: "absolute",
    width: 253,
    height: 253,
    right: "-107px",
    bottom: "-112px",
    background: "#F5C654",
    opacity: 0.2,
    borderRadius: "50%",
  },
  spot2: {
    position: "absolute",
    width: 74.6,
    height: 74.6,
    left: "-30px",
    top: "125.24px",
    background: "#F5C654",
    opacity: 0.2,
    transform: "rotate(-26.46deg)",
  },
  spot3: {
    position: "absolute",
    width: 40,
    height: 40,
    left: "80%",
    top: "175px",
    background: "#F5C654",
    opacity: 0.2,
    transform: "rotate(-26.46deg)",
  },
  spot4: {
    position: "absolute",
    width: "18px",
    height: "117.56px",
    left: "75%",
    top: "-36px",
    background: "#F5C654",
    opacity: 0.2,
    transform: "rotate(46.77deg)",
  },
  spot5: {
    position: "absolute",
    width: "18px",
    height: "117.56px",
    top: 250,
    left: 18,
    background: "#F5C654",
    opacity: 0.2,
    transform: "rotate(46.77deg)",
  },
  spot6: {
    position: "absolute",
    width: "40px",
    height: "40px",
    top: "394.82px",
    left: "32px",
    background: "#F5C654",
    opacity: 0.2,
    transform: "rotate(-26.46deg)"
  }
}));