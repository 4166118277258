import React, { useState } from "react";
import { useSpring } from "@react-spring/core";
import { animated } from "@react-spring/web";

interface Props {
    children: React.ReactNode;
    className: string;
    from: number;
    handleChange: () => void
}


const RightPannel: React.FC<Props> = ({
    children,
    className,
    from,
    handleChange
}) => {
    const styles = useSpring({
        from: { x: from },
        config: { duration: 500 },
        loop: {
            x: 0,
        },
    })
    const [pos, setPos] = useState({ xDown: 0, yDown: 0 })

    const move = (evt: any) => {
        const { xDown, yDown } = pos;
        if (!xDown || !yDown) {
            return;
        }

        var xUp = evt.touches[0].clientX;
        var yUp = evt.touches[0].clientY;

        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;
        if (Math.abs(xDiff) > Math.abs(yDiff) && xDiff > 0 && Math.abs(xDiff) + Math.abs(yDiff) > 100) {/*most significant*/
            console.log("handleChange")
            handleChange()
        }
    }

    const moveStart = (e: any) => {
        var touchobj = e.touches[0]
        const { clientX, clientY } = touchobj;
        setPos({ xDown: clientX, yDown: clientY })
        console.log("moveStart", clientX, clientY)
    }
    const moveEnd = (evt: any) => {
        setPos({ xDown: 0, yDown: 0 })
    }


    return (
        <animated.div style={styles}
            className={className}
        >
            {children}
        </animated.div>
    );
};

export default RightPannel;
