import React, { Component } from "react";
import DataTable from "../../components/table";
import { Data, HeadCell } from "../../components/table/type";
import "./style.scss";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import Loader from "../../components/loader";
import { parseUsers } from "./utils";
import auth from "../../services/auth";
import { notifError, notifSuccess } from "../util";
import { getAllUserAPIs, searchUserAPIs } from "../../services/api-services/users";

const headCells: HeadCell[] = [
    { name: 'cognitoId', label: 'Unique Id' },
    { name: 'name', label: 'Name' },
    { name: 'email', label: 'Email' },
    { name: "action", label: "Action" }
];
export type UserType = {
    cognitoId: string;
    email: string;
    emailStatus: boolean;
    id: number;
    name: string;
    role: string;
    status: boolean;
    action: any;
}
const filterUsers = (users: UserType[], value: string) => (
    users.filter((user: UserType) =>
        user.name.toLowerCase().includes(value.toLowerCase()) ||
        user.email.toLowerCase().includes(value.toLowerCase()) ||
        user.cognitoId.toLowerCase().includes(value.toLowerCase())
    )
)

class Users extends Component<RouteComponentProps, {
    loading: boolean;
    users: UserType[];
    searchUsers: UserType[];
    total: number;
    value: number;
    page: number;
    perPage: number;
}> {
    state = {
        total: 0,
        users: [],
        searchUsers: [],
        value: 0,
        loading: true,
        page: 0,
        perPage: 10

    }
    _loadUsers = async (page = 0, perPage = 10) => {
        console.log((page + 1) * perPage)
        this.setState({ loading: true })
        const res = await getAllUserAPIs(
            !this.state.users.length ? 0 : page,
            !this.state.users.length ? (page + 1) * perPage : perPage
        );
        if (res.status) {
            const users: UserType[] = [...this.state.users, ...parseUsers(res.data.content, this.props.history)]
            this.setState({
                loading: false,
                users,
                total: res.data.totalElements,
                page: !this.state.users.length && res.data.totalElements < (page + 1) * perPage ? 0 : page,
                perPage
            })
        }

        else {
            notifError("Users", "something went wrong.")
            this.setState({ loading: false })
        }
    }

    async componentDidMount() {
        let search = new URLSearchParams(this.props.location.search);
        let page = Number((search).has("page")) || 0,
            perPage = Number(search.get("per_page")) || 10;
        this.props.history.push({
            search: `?page=${page}&per_page=${perPage}`
        })
        this._loadUsers(page, perPage)
    }
    onPageChange = async (perPage: number, page: number) => {
        this.props.history.push({
            search: `?page=${page}&per_page=${perPage}`
        })
        const { perPage: per_page, total, users = [] } = this.state;
        if (per_page !== perPage) {
            if (users.length >= perPage) {
                const point = users.length / perPage
                const data = users.slice(0, Number(point) * perPage)
                this.setState({ users: data, page: 0, perPage })
            } else {
                this.setState({ users: [] })
                await this._loadUsers(page, perPage);
            }
        } else {
            if (total > users.length && (page + 1) * perPage > users.length)
                await this._loadUsers(page, perPage);
        }
        return this.state.users;
    }
    onSearch = async (value: string) => {
        const existsUser = filterUsers(this.state.users, value);
        if (value && value.length > 2 && !existsUser.length) {
            if (value.length < this.state.value) {
                return filterUsers(this.state.searchUsers, value);
            } else {
                this.setState({ loading: true, value: value.length })
                const resp = await searchUserAPIs(value);
                if (resp.status) {
                    const users = parseUsers(resp.data, this.props.history);
                    this.setState({ loading: false, searchUsers: users })
                    return users;
                } else {
                    notifError("ERROR", "Something went wrong.")
                    this.setState({ loading: false })
                    return existsUser
                }
            }
        }
        else
            return existsUser
    }
    onCancelSearch = () => {
        return this.state.users;
    }
    render() {
        const { users, total, page, perPage } = this.state;
        return (
            <div className="root-container">
                <Loader show={this.state.loading} />
                <DataTable

                    page={page}
                    perPage={perPage}
                    onPageChange={this.onPageChange}
                    total={total}
                    onCancelSearch={this.onCancelSearch}
                    onSearch={this.onSearch}
                    title={"Users"}
                    headers={headCells}
                    data={users}
                >
                    <></>
                </DataTable>
            </div>
        );
    }
}

export default withRouter(Users);