import React, { Component } from "react";
import DataTable from "../../components/table";
import { Data, HeadCell } from "../../components/table/type";
import "./style.scss";
import { Link, withRouter } from "react-router-dom";
import Button from "../../components/button";
import { deleteTagAPI, getTagsAPI, searchTagsAPIs } from "../../services/api-services/tags";
import Loader from "../../components/loader";
import { parseTags } from "./utils";
import auth from "../../services/auth";
import { notifError, notifSuccess } from "../util";
import { deleteTagsFromDB, getAllTagsFromDB } from "../../indexedDB/tags";

const headCells: HeadCell[] = [
    { name: 'name', label: 'Name' },
    { name: 'img', label: 'Image' },
    // { name: 'airports', label: 'Total Airports' },
    { name: 'status', label: 'Status' },
    { name: "action", label: "Action" }
];

const filterTags = (tags: Data[], value: string) => (
    tags.filter((user: Data) =>
        user.name.toLowerCase().includes(value.toLowerCase())
    )
)
class Tags extends Component<any, {
    loading: boolean;
    tags: Data[];
    value: number;
    searchTags: Data[];
}> {
    state = {
        tags: [],
        loading: true,
        value: 0,
        searchTags: []
    }

    async componentDidMount() {
        let res = await getAllTagsFromDB()
        if (res)
            this.setState({ loading: false, tags: parseTags(res, this.props.history) })
        else {
            notifError("Tags", "something went wrong.")
            this.setState({ loading: false })
        }
    }

    onDeleteTag = async (ids: string[]) => {
        let { tags } = this.state;
        this.setState({ loading: true })
        for (let id of ids) {
            let res = await deleteTagAPI(id, auth.getToken());
            if (res.status) {
                await deleteTagsFromDB(Number(id))
                let deleted: any = tags.find((item: any) => item.id.toString() == id);
                tags = tags.filter((item: any) => item.id.toString() !== id);
                notifSuccess("Tags", `${deleted?.name} tag has been deleted successfully.`)
            } else notifError("Tags", "something went wrong")
        }
        this.setState({ tags, loading: false })
    };
    onSearch = async (value: string) => {
        const existsTags = filterTags(this.state.tags, value);
        if (value && value.length > 2 && !existsTags.length) {
            if (value.length < this.state.value) {
                return filterTags(this.state.searchTags, value);
            } else {
                this.setState({ loading: true, value: value.length })
                const resp = await searchTagsAPIs(value);
                if (resp.status) {
                    const tags = parseTags(resp.data, this.props.history);
                    this.setState({ loading: false, searchTags: tags })
                    return tags;
                } else {
                    notifError("ERROR", "Something went wrong.")
                    this.setState({ loading: false })
                    return existsTags
                }
            }
        }
        else
            return existsTags
    }
    onCancelSearch = () => {
        return this.state.tags;
    }
    render() {
        return (
            <div className="root-container">
                <Loader show={this.state.loading} />
                <DataTable
                    onDelete={this.onDeleteTag}
                    title={"Tags"}
                    onCancelSearch={this.onCancelSearch}
                    onSearch={this.onSearch}
                    headers={headCells}
                    data={this.state.tags}
                >
                    <Link to="/tags/add">
                        <Button variant="contained" btnText={"+  Tag"} onClick={() => { }} />
                    </Link>
                </DataTable>
            </div>
        );
    }
}

export default withRouter(Tags);