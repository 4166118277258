import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { style } from "./style";

interface Props {
  vertical: boolean;
  classes: any;
  className?: string;
}

const Line: React.FC<Props> = ({ vertical, classes, className = "" }) => {
  // let mode = vertical ? classes.verticalView : classes.horizontal;
  // let lo = vertical ? classes.verticalView : classes.horizontal;
  return (
    <div className={`${vertical ? classes.verticalView : classes.horizontalView} ${className}`}>
      <div className={classes.circleBorder}></div>
      <div
        className={vertical ? classes.verticalLine : classes.horizontalLine}
      ></div>
      <div className={classes.circleBorder}></div>
    </div>
  );
};

export default withStyles(style)(Line);
