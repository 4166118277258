import React from "react";
import "./styles.scss";

interface headingProps {
    heading: string;
    back?: boolean;

}

const SmallFormHeading: React.FC<headingProps> = ({ heading, back = false }) => (
    <div id="small-form-heading">

        <div className={back ? "heading back-heading" : "heading"}>{heading}</div>
        {!back &&
            <>
                <div className="ellipse17"></div>
                <div className="rectangle30"></div>
                <div className="ellipse18"></div>
                <div className="rectangle31"></div>
            </>}
    </div >
)
export default SmallFormHeading;