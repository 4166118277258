import React, { Component } from "react";
import "./style.scss";
import Form from "../../components/FormComponents";
import { fields } from "./fields"
import RightPannel from "../../components/Authentication/right-pannel";
import Button from "../../components/button";
import Loader from "../../components/loader";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { notifError, notifSuccess } from "../util";
import { addPlanAPIs } from "../../services/api-services/plans";
import { PlansType } from ".";

interface StateParams {
    loading: boolean;
}
const initialValues = { country: "United States", status: "true", flight_class: "ECONOMY" };
class AddPlan extends Component<RouteComponentProps, StateParams> {
    state = {
        loading: false,
    }

    onSubmit = async ({ month = 0, flights = 0, ...rest }: PlansType, resetForm: any) => {
        this.setState({ loading: true })
        let res = await addPlanAPIs({ month, flights, ...rest })
        if (res.status) {
            notifSuccess("Plans", "Plan has been added successfully.")
            this.props.history.push("/plans")
        }
        else notifError("Plans", `something went wrong`)
        this.setState({ loading: false })
    }
    handleCancel = () => {
        this.props.history.push("/plans")
    }
    render() {
        return (
            <div className="root-container form-center">
                <Loader show={this.state.loading} />
                <div className="flexBox-root">
                    <div id="add-tags">
                        <RightPannel
                            className="rightPanel-container"
                            from={0} handleChange={() => { }}>
                            <div className="rightPanel">
                                <div className="title">Add New Plan</div>
                                <div className="line"></div>
                                <div className="form">
                                    <Form
                                        fields={fields}
                                        onSubmit={this.onSubmit}
                                        initialValues={initialValues}
                                        btnText={"Add"}
                                        renderCustomSubmit={

                                            <div className="bottomSection">
                                                <div className="forgotPassWord"></div>
                                                <Button onClick={this.handleCancel} btnText={"Cancel"} variant={"contained"} />
                                                <Button onClick={() => { }} btnText={"Add"} variant={"contained"} />
                                            </div>
                                        }
                                    />

                                </div>
                            </div>
                        </RightPannel>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AddPlan);