import React, { Component } from "react";
import "./style.scss";
import Form from "../../components/FormComponents";
import { fields } from "./fields"
import RightPannel from "../../components/Authentication/right-pannel";
import Button from "../../components/button";
import Loader from "../../components/loader";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { notifError, notifSuccess } from "../util";
import { updatePlanAPIs } from "../../services/api-services/plans";
import { PlansType } from ".";

interface StateParams {
    loading: boolean;
    plan: PlansType;
}
class EditPlan extends Component<RouteComponentProps, StateParams> {
    state = {
        loading: true,
        plan: {
            description: "",
            flight_class: "",
            id: 0,
            month: 0,
            name: "",
            status: "true",
            price: 0,
            flights: 0,
            country: "United States"

        }
    }
    componentDidMount() {
        const { state }: any = this.props.location;
        const { id = 0 }: any = this.props.match.params;
        if (state && id) {
            const { country, status = "true", ...plan }: PlansType = state;
            this.setState({ plan: { country: country || "United States", status: status.toString(), ...plan }, loading: false })
        } else {
            notifError("Error", "Please select a valid user.")
            this.props.history.push("/plans")
        }
    }
    onSubmit = async (payload: PlansType, resetForm: any) => {
        this.setState({ loading: true })
        let res = await updatePlanAPIs(payload, this.state.plan.id)
        if (res.status) {
            notifSuccess("Plans", "Plan has been updated successfully.")
            this.props.history.push("/plans")
        }
        else notifError("Plans", `something went wrong`)
        this.setState({ loading: false })
    }
    handleCancel = () => {
        this.props.history.push("/plans")
    }
    render() {
        const { loading, plan } = this.state;
        return (
            <div className="root-container form-center">
                <Loader show={loading} />
                <div className="flexBox-root">
                    <div id="add-tags">
                        <RightPannel
                            className="rightPanel-container"
                            from={0} handleChange={() => { }}>
                            <div className="rightPanel">
                                <div className="title">Edit Plan</div>
                                <div className="line"></div>
                                <div className="form">
                                    <Form
                                        fields={fields}
                                        onSubmit={this.onSubmit}
                                        initialValues={plan}
                                        btnText={"Update"}
                                        renderCustomSubmit={

                                            <div className="bottomSection">
                                                <Button onClick={this.handleCancel} btnText={"Cancel"} variant={"contained"} />
                                                <Button onClick={() => { }} btnText={"Update"} variant={"contained"} />
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        </RightPannel>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(EditPlan);