import React from "react";
import LeftPannel from "../../LeftPannel";
import Form from "../../FormComponents";
import "./index.scss";
import Button from "../../button";
import { field } from "../../FormComponents/types";
import RightPannel from "../right-pannel";
import SmallFormHeading from "../common/small-form-heading";

interface Props {
  fields: field[];
  btnText: string;
  pannelButtonText: string;
  btnType: string;
  panelButtonType: string;
  onSubmit: (payload: any, resetForm: any) => Promise<void>;
  onForgot?: () => void;
  onleftPanel: () => void;
  back?: boolean;
}

const SignIn: React.FC<Props> = ({
  btnText,
  btnType,
  panelButtonType,
  fields,
  onSubmit,
  onleftPanel,
  back
}) => {
  const initialValues = { email: "", password: "" };
  const customButton = (
    <div className="bottomSection">
      <div className="forgotPassWord"></div>
      <Button onClick={() => { }} btnText={btnText} variant={btnType} />
    </div>
  );
  return (
    <>
      <div id="sign-in">
        <LeftPannel
          from={500}
          txtFrom={-1200}
          header="Hello, Admin!"
          message="Enter your details and start journey with us"
          buttonText={""}
          buttonType={panelButtonType}
          onClick={onleftPanel}>
          <div className={"spot1"}></div>
          <div className={"spot2"}></div>
          <div className={"spot3"}></div>
        </LeftPannel>

        <RightPannel
          handleChange={onleftPanel}
          from={-500}
          className="rightPanel-container"
        >
          <SmallFormHeading back={back} heading={btnText} />

          <div className="rightPanel">
            <div className="signClass">{btnText}</div>
            <div className="line"></div>
            <div className="form">
              <Form
                fields={fields}
                onSubmit={onSubmit}
                initialValues={initialValues}
                btnText={btnText}
                renderCustomSubmit={customButton}
              />
            </div>
          </div>
        </RightPannel>
      </div>
    </ >
  );
};

export default SignIn;
