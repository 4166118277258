import { UserType } from ".";
import { ActionPara } from "../../components/table/type";

const getActions = (history: any, id: number): ActionPara[] => [
	{
		name: "Travellers",
		action: () =>
			history.push("/users/travellers/" + id),
	},
	{
		name: "Plans",
		action: () =>
			history.push("/users/Plans/" + id),
	},
	{
		name: "Booking",
		action: () =>
			history.push("/users/booking/" + id),
	},
];


const parseUsers = (users: any, history: any): UserType[] => {
	let parse = users.map(
		({ id, ...rest }: UserType) => ({
			...rest,
			id,
			action: getActions(history, id),
		})
	);
	return parse;
};

export { parseUsers };
