import React, { useEffect } from "react";
import { Theme, makeStyles } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import {
  Public,
  LocalOffer,
  ExitToApp,
  PlayArrow,
  ArrowForwardIos,
  Group,
  FiberManualRecord,
  ClearAll,
  Bookmark,
  FilterList
} from "@material-ui/icons";
interface Style {
  backgroudColor: string;
  fontSize: string;
  IconColor: string;
  fontColor: string;
  drawerWidth: number;
  closeDrawerWidth: number;
  selectedMenuColor: string;
}

const styles = makeStyles<Theme, Style>((theme) => ({
  textItem: {
    color: (props) => (props.fontColor ? props.fontColor : "#333"),
    fontWeight: "bold",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: (props) => (props.fontSize ? props.fontSize : 16),
  },
  selected: {
    background: (props) =>
      props.selectedMenuColor ? props.selectedMenuColor : "#F2F5F8",
    borderRadius: 4,
  },
  list: {
    paddingBottom: 0,
    marginTop: 20,
  },
  icon_class_container: {
    margin: "0px 20px",
    height: 21,
    display: "flex",
    alignItems: "center",
    marginBottom: 14,
  },
  drop_down_icon_open: {
    webkitTransform: "rotate(90deg)",
    transform: "rotate(90deg)",
  },
  drop_down_icon_close: {
    webkitTransform: "rotate(0deg)",
    transform: "rotate(0deg)",
  },
  headerIcons: {
    width: 40,
    marginRight: 10,
    position: "relative",
  },
  displayFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  listItemChildren: {
    position: "relative",
    width: "140px",
    height: "30px",
    marginTop: 5,
    marginLeft: "67px",
    marginRight: 21,
    textDecoration: "none",
    "&:hover": {
      color: "Black",
      textDecoration: "none",
    },
  },
  horizontalBar: {
    width: 200,
    margin: "30px 14px",
    background: "white",
    opacity: .4,
  },
  horizontalBarClose: {
    width: 34,
    margin: "30px 13px",
    background: "white",
    opacity: .4,
  },
  subTitle: {
    fontSize: 12,
    width: 110,
    height: 14,
    position: "relative",
    color: (props) => (props.fontColor ? props.fontColor : "Black"),
    textDecoration: "none",
    "&:hover": {
      color: (props) => (props.fontColor ? props.fontColor : "Black"),
      textDecoration: "none",
    },
  },
  icon: {
    fontSize: 14,
    color: (props) => (props.IconColor ? props.IconColor : "#717171"),
    marginLeft: 5,
  },
  iconClose: {
    display: "none",
  },
  color: {
    color: (props) => (props.IconColor ? props.IconColor : "#717171"),
  },
  dot: {
    color: (props) => (props.IconColor ? props.IconColor : "#FD0000"),
    position: "absolute",
    top: -2,
    left: 18,
    fontSize: 9,
  },
  dotInner: {
    color: (props) => (props.IconColor ? props.IconColor : "#FD0000"),
    position: "absolute",
    top: 0,
    left: 68,
    fontSize: 8,
  },
  listHeading: {
    textDecoration: "none",
    "&:hover": {
      color: "Black",
      textDecoration: "none",
    },
  },
  active: {
    color: (props) => (props.selectedMenuColor ? props.selectedMenuColor : "#FD0000"),
  }
})) as any;

interface Children {
  title?: string;
  to?: string;
  component?: () => JSX.Element;
}

interface MenuItems {
  title?: string;
  icon_type?: string;
  to?: string;
  component?: () => JSX.Element;
  children?: Children[];
}

interface MenuProps {
  menu?: MenuItems[];
  isOpen: boolean;
  className: Style;
  toggleDrawer: any;
}

const getIcon = (icon_type: string, color: string) => {
  switch (icon_type) {
    case "tags":
      return <Bookmark className={color} />;
    case "users":
      return <Group className={color} />;
    case "Public":
      return <Public className={color} />;
    case "offer":
      return <LocalOffer className={color} />;
    case "filters":
      return <FilterList className={color} />;
    case "Logout":
      return <ExitToApp className={color} />;
    default:
      return <ClearAll className={color} />;
  }
};
const isActive = (children: any) => {
  return children && children.length && children.find((item: any) => window.location.pathname === item.to)
}
const NavListWithCollapse: React.FC<MenuProps> = ({
  menu,
  isOpen,
  className,
  toggleDrawer
}) => {
  const classes = styles(className);
  const {
    icon_class_container,
    textItem,
    selected,
    list,
    listHeading,
    drop_down_icon_open,
    drop_down_icon_close,
    displayFlex,
    headerIcons,
    listItemChildren,
    horizontalBar,
    subTitle,
    icon,
    color,
    active,
    iconClose,
    horizontalBarClose,
    dot,
    dotInner,
  } = classes;
  useEffect(() => {
    set_data({})
  }, [isOpen])
  const [data_open, set_data] = React.useState<any>({});
  const [isSelected, setSelected] = React.useState();
  const is_parent = (children: any) => Boolean(children) && Boolean(children.length);
  const open_data = (property: string) =>
    set_data({ ...data_open, [property]: !data_open[property] });
  const handleClick = (title: any) => {
    setSelected(title);
  };

  const onClick = (title: string) => {
    if (isOpen) {
      open_data(title);
    }
  };
  const openSideBar = () => {
    if (!isOpen) {
      toggleDrawer();
    }
  }
  const handleEvent = (onEvent: any, title: string) => {
    if (onEvent) { onEvent() } else handleClick(title)
    toggleDrawer();
  }
  return (
    <div className={list}>
      {menu && menu.length
        ? menu.map(({ to, component, icon_type, title, children }: any, index) => {
          const activeColor = isActive(children) ? active : color;
          return (
            <React.Fragment key={index}>
              <Link
                to={to}
                onClick={() => onClick(title)}
                className={listHeading}
              >
                <div className={icon_class_container} onClick={openSideBar}>
                  <div className={`${displayFlex} ${headerIcons}`}>
                    {getIcon(icon_type, activeColor)}
                    {children && children.length ? (
                      <ArrowForwardIos
                        className={`${isOpen ? icon : iconClose}
                            ${data_open[title || "No title"]
                            ? drop_down_icon_open
                            : drop_down_icon_close
                          } ${activeColor}`}
                      />
                    ) : null}
                  </div>
                  <div className={`${textItem} ${activeColor}`}>{title || "No title"}</div>
                </div>
              </Link>
              {children && children.length ? (
                <Collapse
                  in={data_open[title || "No title"]}
                  timeout="auto"
                  unmountOnExit
                >
                  <div>
                    {children.map(({ to, title, onEvent, status = false }: any, index: number) => (
                      <>{!status && <Link
                        style={{ color: window.location.pathname === to ? active : color }}
                        key={index}
                        to={to}
                        onClick={() => handleEvent(onEvent, title)}
                        className={`${displayFlex} ${listItemChildren}`}
                      >
                        <div className={`${subTitle} ${window.location.pathname === to ? active : color}`}>
                          {title}
                        </div>
                        <PlayArrow className={`${icon} ${window.location.pathname === to ? active : color}`} />
                      </Link>}</>
                    ))}
                  </div>
                </Collapse>
              ) : null}
              <Divider
                className={isOpen ? horizontalBar : horizontalBarClose}
              />
            </React.Fragment>
          );
        })
        : null}
    </div >
  );
};

export default React.memo(NavListWithCollapse);
