import React, { useState } from "react";
import "./index.scss";
import { TextField, InputAdornment, FormControl } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface Form {
  errors: Object;
  touched: Object;
}

interface Props {
  icon?: any;
  variant: string;
  className: string;
  inline: boolean;
  labelText: string;
  labelPositionLeft: Boolean;
  labelCss: string;
  form: any;
  field: any;
  value: string | number;
  type?: string;
  create_err_msg?: any;
  placeholder?: string;
  minDate: any;
  maxDate: any;
  style?: any;
  showMonthYearPicker?: boolean;
  dateFormat?: string;
}

const iconComponentProps = (
  icon: any,
): any => ({
  InputProps: {
    startAdornment: icon ? (
      <InputAdornment
        position="start"
        className="visibility-icon"
      >
        {icon}
      </InputAdornment>
    ) : null,
  }
});


const DatePick: React.FC<Props> = React.memo(
  ({
    style = {},
    icon,
    minDate = "",
    maxDate = "",
    variant,
    className,
    labelCss,
    showMonthYearPicker = false,
    dateFormat = undefined,
    labelPositionLeft,
    form: { values, setFieldValue, touched, errors },
    field,
    placeholder = "",
    ...rest
  }) => {
    const handleDateSelect = (date: any) => {
      setFieldValue(field.name, date);
    }
    const handleDateChangeRaw = (e: any) => {
      e.preventDefault();
    }
    const variantCss =
      variant === "tripStarter"
        ? "primary"
        : variant === "Outlined"
          ? "outlined"
          : variant === "Dark"
            ? "dark-outlined"
            : "outlined";
    return (
      <>
        <div className={`${labelPositionLeft && "flex-box-input"} ${variantCss}`} style={style}>
          <div className={`label-text ${labelCss}`}>{rest.labelText}</div>
          {icon && <div className='icon-root left-10'>{icon}</div>
          }
          <DatePicker
            autoComplete={"off"}
            required
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            minDate={minDate}
            maxDate={maxDate}
            onMonthChange={handleDateSelect}
            onYearChange={handleDateSelect}
            selected={values ? values[field.name] : null}
            onSelect={handleDateSelect} //when day is clicked
            onChangeRaw={handleDateChangeRaw}
            // onChange={handleDateChange} //only when value has changed
            id="date-picker"
            className={`${className} MuiInput-root`}
            placeholderText={placeholder}
            {...iconComponentProps(icon)}
            dateFormat={dateFormat}
            showMonthYearPicker={showMonthYearPicker}
          />
        </div>
        {
          errors && touched && errors[field.name] && (
            <div className="error-text">
              {rest.create_err_msg
                ? rest.create_err_msg(field.value)
                : errors[field.name]}
            </div>
          )
        }
      </>
    );
  }
);

export default DatePick;
