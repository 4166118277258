import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./index.scss";

interface Props {
  icon?: any;
  variant: string;
  className: string;
  inline: boolean;
  labelText: string;
  labelPositionLeft: Boolean;
  labelCss: string;
  form: any;
  field: any;
  value: string | number;
  type?: string;
  placeholder: string;
  create_err_msg?: any;
  editId: any;
  defaultValue?: string;
}

const ContactField: React.FC<Props> = ({
  form,
  field,
  editId,
  defaultValue,
  placeholder,
  ...rest
}) => {
  const handleChange = (value: any, data: any) => {
    form.setFieldValue(field.name, value);
    form.setFieldValue("mobile", value.slice(data.dialCode.length));
    form.setFieldValue("countryCode", data.dialCode);
  };

  // const handleBlur = () => {
  //   form.setTouched({
  //     ...form.touched,
  //     [field.name]: true,
  //   });
  // };

  return (
    <>
      <div style={{
        marginLeft: 3,
        display: "flex",
        alignItems: "center",
        height: "100%"
      }}>
        <PhoneInput
          country={defaultValue || "us"}
          countryCodeEditable={false}
          placeholder={placeholder}
          value={form.values[field.name] || "+91"}
          containerStyle={{
            backgroundColor: "rgba(21, 39, 75, 0.15)",
            borderRadius: "4px",
          }}
          inputStyle={{
            backgroundColor: "transparent",
            width: "100%",
            color: "#ffff",
            border: "none",
            height: "44px",
          }}
          buttonStyle={{
            backgroundColor: "transparent",
            border: "none",
          }}
          onChange={handleChange}
        // onBlur={handleBlur}
        />
      </div>
      {form.errors[field.name] && (
        <div className="error-text" style={{ marginTop: -10 }}>{form.errors[field.name]}</div>
      )}
    </>
  );
};

export default ContactField;
