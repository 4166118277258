import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TrendingUp } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: "20px 0px !important",
            width: "100%",
            minWidth: 120,

        },
    }),
);

const TagLevel: React.FC<{
    onLevelChange: (e: any) => void;
    value: number;
}> = ({ onLevelChange, value }) => {
    const classes = useStyles();

    return (
        <div>
            <FormControl className={`${classes.formControl} primary`}>
                <TrendingUp className="trending-icon" />
                <Select native value={value} onChange={onLevelChange} id="grouped-native-select">
                    <option value={0}>Level 0</option>
                    <option value={1}>Level 1</option>
                    <option value={2}>Level 2</option>
                    <option value={3}>Level 3</option>
                    <option value={4}>Level 4</option>
                    <option value={5}>Level 5</option>
                    <option value={6}>Level 6</option>
                    <option value={7}>Level 7</option>
                </Select>
            </FormControl>
        </div >
    );
}
export default TagLevel;