import React, { Component } from "react";
import NavDrawer from "../components/NavDrawer";
import { themeProps } from "../components/NavDrawer/type";
import SignIn from "./sign-in";
import Tags from "./tags";
import AddTag from "./tags/add";
import Airports from "./airports";
import EditTags from "./tags/edit";
import auth from "../services/auth";
import Users from "../routes/users"
import UserPlans from "../routes/users/plans"
import EditUserPlans from "../routes/users/plans/update"
import UserBooking from "../routes/users/booking";
import UserTrip from "../routes/users/trip"
import Travellers from "./travellers";
import TravellerUpdate from "./travellers/update";
import Plans from "../routes/plans";
import AddPlan from "../routes/plans/add";
import EditPlan from "../routes/plans/edit";
import Filters from "./filters";
import Airlines from "./filters/airlines";
import AddAirlines from "./filters/airlines/add";
import EditAirlines from "./filters/airlines/edit";

const className = {
    backgroudColor: "#C29524",
    fontSize: "15px",
    IconColor: "#FFFFFF",
    fontColor: "#FFFFFF",
    drawerWidth: 230,
    closeDrawerWidth: 70,
    selectedMenuColor: "black"
}
const theme: themeProps = {
    onThemeChange: (color: string) => { alert("color: " + color) },
    colorCodes: []
}

const privateMenu = [
    {
        title: "Tags",
        icon_type: "tags",
        children: [
            {
                title: "Tags",
                to: "/tags",
                component: () => <Tags />

            },

            {
                title: "Add New Tag",
                to: "/tags/add",
                component: () => <AddTag />

            },
            {
                title: "Airports",
                to: "/tags/airports/:name",
                component: () => <Airports />,
                status: true
            },
            {
                title: "Edit Tags",
                to: "/tags/edit/:name",
                component: () => <EditTags />,
                status: true
            },
        ]

    },
    {
        title: "Filters",
        icon_type: "filters",
        component: () => <Filters />,
        children: [
            {
                title: "Airline",
                to: "/filters/airlines",
                component: () => <Airlines />

            },
            {
                title: "Add New Airline",
                to: "/filters/airlines/add",
                component: () => <AddAirlines />

            },
            {
                title: "Edit Airline",
                to: "/filters/airlines/:code",
                component: () => <EditAirlines />,
                status: true
            },
        ]

    },
    {
        title: "Users",
        icon_type: "users",
        children: [
            {
                title: "Users",
                to: "/users",
                component: () => <Users />
            },
            {
                title: "Travellers",
                to: "/users/travellers/:id",
                component: () => <Travellers />,
                status: true
            },
            {
                title: "Plans",
                to: "/users/plans/:id",
                component: () => <UserPlans />,
                status: true
            },
            {
                title: "Plans",
                to: "/users/plans/:id/update/:plan_id",
                component: () => <EditUserPlans />,
                status: true
            },
            {
                title: "Booking",
                to: "/users/booking/:id",
                component: () => <UserBooking />,
                status: true
            },
            {
                title: "Trip",
                to: "/users/booking/:id/trip/:tid",
                component: () => <UserTrip />,
                status: true
            },
            {
                title: "Travellers",
                to: "/users/travellers/update/:id",
                component: () => <TravellerUpdate />,
                status: true
            },
        ]
    },
    {
        title: "Plans",
        icon_type: "offer",
        children: [
            {
                title: "Plans",
                to: "/plans",
                component: () => <Plans />
            },
            {
                title: "Add New Plan",
                to: "/plans/add",
                component: () => <AddPlan />

            },
            {
                title: "edit Plan",
                to: "/plans/edit/:id",
                component: () => <EditPlan />,
                status: true
            },
        ]
    },
    {
        title: "Logout",
        icon_type: "Logout",
        children: [
            {
                title: "Logout",
                to: "/logout",
                onEvent: async () => {
                    await auth.logout();
                    window.location.href = "/"
                }

            },
        ]
    },
]
class NavBar extends Component<{ user: object | null; }, {}> {

    render() {
        const { user } = this.props;
        return (<>
            {user ? <NavDrawer menu={privateMenu}
                theme={theme}
                className={className}
                {...this.props} /> : < SignIn />
            }
        </>)
    }

}
export default NavBar;