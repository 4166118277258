import React, { Component, useState } from "react";
import SignIn from "../../components/Authentication/SignIn";
import "./style.scss";
import { SignProps } from "../../components/Authentication/type";
import Modal from '@material-ui/core/Modal';
import { notifError, notifSuccess } from "../util";
import { signInFields } from "./fields";
import Loader from "../../components/loader";
import auth from "../../services/auth";
import { profileAPI } from "../../services/api-services";

const signInContent: SignProps = {
    fields: signInFields,
    pannelButtonText: "Sign up",
    panelButtonType: "outlined",
    btnText: "Sign in",
    btnType: "contained",
}


class LogIn extends Component<any, {
    loading: boolean;
}> {
    state = { loading: false }

    doSignin = async (payload: any, { resetForm }: any) => {
        this.setState({ loading: true })
        const { email, password } = payload;
        let resp = await auth.login(email, password)
        if (resp && !resp.error) {
            let res = await profileAPI(resp.data)
            if (res.status && res.data.role === "Admin") {
                notifSuccess("TripStarter", "Welcome to tripStarter.")
                window.location.href = "/"
            } else {
                notifError("login", "User not allow.")
                await auth.logout()
            }
        }
        this.setState({ loading: false })
    };


    render() {
        const { loading } = this.state;
        return (
            <Modal
                className="modal"
                open={true}
            >
                <>
                    <Loader show={loading} />
                    <div className="sign-root">
                        <div className="flexBox-root">
                            <SignIn {...signInContent} onSubmit={this.doSignin} onForgot={() => { }} onleftPanel={() => { }} />
                        </div>
                    </div>
                </>
            </Modal >
        )
    }

}
export default LogIn;