import { store as notify } from "react-notifications-component";

export const notifAlert = (title: string, message?: string) => {
  notify.addNotification({
    title,
    message,
    type: "info",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

export const notifError = (title: string, message?: string) => {
  notify.addNotification({
    title,
    message,
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

export const notifSuccess = (title: string, message?: string) => {
  notify.addNotification({
    title,
    message,
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

export const notifWarning = (title: string, message?: string) => {
  notify.addNotification({
    title,
    message,
    type: "warning",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

export const verifyPassword = (value: string): string => {
  if (
    value.length < 8 ||
    !/[a-z]/.test(value) ||
    !/[A-Z]/.test(value) ||
    !/[0-9]/.test(value) ||
    !/[!@#\$%\^&\*]/.test(value)
  ) {
    return "Password should contain : \n Minimum length of 8 characters \n Atleast 1 Numeric character \n Atleast 1 Uppercase letter \n Atleast 1 Lowercase letter \n Atleast 1 Special character";
  }

  return "";
};

export const isMobileView = () => {
  return window.innerWidth <= 800
}