import React from "react";
import { Theme, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import NavListWithCollapse from "./NavListWithCollapse";
import Header from "./NavHeader";
import { themeProps } from "./type";
const drawer_width = 228;

interface Style {
  backgroudColor: string;
  fontSize: string;
  IconColor: string;
  fontColor: string;
  drawerWidth: number;
  closeDrawerWidth: number;
  selectedMenuColor: string;
}

const styles = makeStyles<Theme, Style>((theme) => ({
  root: {
    width: (props) => (props.drawerWidth ? props.drawerWidth : drawer_width),
    background: (props) =>
      props.backgroudColor ? props.backgroudColor : drawer_width,
    flexWrap: "nowrap",
    flex: 1,
    overflow: "hidden",
  },
  container: {
    flex: 1,
    overflowY: "auto",
    overflowX: "hidden",
  },
  themeBox: {
    width: 25,
    height: 25,
    cursor: "pointer",
    "&:hover": {
      width: 26,
      height: 26,
    },
    margin: 5,
  },
  themeRoot: {
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    margin: 15,
    minWidth: 180,
  }
})) as any;

interface Children {
  title?: string;
  to?: string;
  component?: () => JSX.Element;
}

interface MenuItems {
  title?: string;
  icon_type?: string;
  to?: string;
  component?: () => JSX.Element;
  children?: Children[];
}

interface MenuProps {
  menu?: MenuItems[];
  toggleDrawer: () => void;
  isOpen: boolean;
  className: Style;
  theme: themeProps;
}

const NavContent: React.FC<MenuProps> = ({
  isOpen,
  toggleDrawer,
  menu,
  className,
  theme
}) => {
  const classes = styles(className);
  const { root, container } = classes;
  const { colorCodes, onThemeChange } = theme;
  const themeHandle = (color: string) => {
    if (onThemeChange) {
      onThemeChange(color)
    }
  }
  const renderTheme = (colorCode: string, index: number) => (
    <Grid xs={2}>
      <div onClick={themeHandle.bind(this, colorCode)} key={index} className={classes.themeBox} style={{ background: colorCode }}></div>
    </Grid>
  )
  return (
    <Grid container direction="column" className={root}>
      <Grid direction="column" container wrap="nowrap" className={container}>
        <Header toggleDrawer={toggleDrawer} />
        <NavListWithCollapse
          className={className}
          isOpen={isOpen}
          menu={menu}
          toggleDrawer={toggleDrawer}
        />
        {colorCodes && colorCodes.length ?
          <div className={classes.themeRoot}>
            <Grid container>
              {colorCodes.map(renderTheme)}
            </Grid>
          </div> : null}
      </Grid>
    </Grid>
  );
};

export default React.memo(NavContent);
