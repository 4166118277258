import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "./index.scss";
import { OptionType } from "../../FormComponents/types";


interface Props {
  labelText: string;
  options: OptionType[];
  form: any;
  field: any;
  value: string;
  labelCss: string;
  labelPositionLeft: Boolean;
  name: string;
}

const RadioList: React.FC<Props> = React.memo(
  ({
    labelCss,
    name,
    labelPositionLeft,
    value,
    options,
    field,
    form,
    ...rest
  }) => {
    const renderRadio = (option: OptionType, index: number) => (
      <div className="radio">
        <FormControlLabel
          key={index}
          name={field.name}
          value={option.id}
          control={<Radio />}
          label={option.label}
        />
      </div>
    );
    return (
      <>
        <div
          id="radio-button"
          className={labelPositionLeft && "flex-box-radio"}
        >
          <div
            className={`label-text-radio ${labelCss} ${labelPositionLeft && "margin-label-radio"
              }`}
          >
            {rest.labelText}
          </div>
          <RadioGroup
            value={field && field.value}
            name={field && field.name}
            onChange={form.handleChange(field.name)}
          >
            {options && options.map(renderRadio)}
          </RadioGroup>
        </div>
        {form && form.errors[field.name] && form.touched[field.name] && (
          <div className="error-text-radio">{form.errors[field.name]}</div>
        )}
      </>
    );
  }
);

export default RadioList;
