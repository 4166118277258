import * as Yup from "yup";
import { field } from "./types";
import moment from "moment";

export const createSchema = (validations: field[]) => {
  let shape: any = {};
  for (let i = 0; i < validations.length; i++) {
    let _type = validations[i].type;
    let _key = validations[i].name;
    let _required = validations[i].required;
    let _req_msg = validations[i].req_msg;
    let _err_msg = validations[i].err_msg;
    let _min_date = validations[i].min_date;
    let _max_date = validations[i].max_date;
    let _matches = validations[i].matches;
    let _min = validations[i].min;
    let _max = validations[i].max;

    if (_type === "email") {
      shape[_key] = Yup.string().email(
        _err_msg || "Please provide a valid email addess"
      );
    } else if (_type === "number") {
      shape[_key] = Yup.number();
      if (_min)
        shape[_key] = shape[_key].min(
          _min,
          `Minimum ${_key} should be ${_min}`
        );
      if (_max)
        shape[_key] = shape[_key].max(
          _max,
          `Maximum ${_key} should be ${_max}`
        );
    } else if (_type === "full_name") {
      const max = _max || 256;
      shape[_key] = Yup.mixed().test(
        "match",
        "Full Name is required",
        function (name) {
          return name && name["First name"] && name["Last name"];
        }
      ).test(
        "match",
        'Full Name must be less than ' + max + ' characters',
        function (name) {
          if (name) {
            const fL = name["First name"] || "",
              lL = name["Last name"] || "",
              mL = name["Middle name"] || "";
            return name && fL.length + lL.length + mL.length <= max;

          } else
            return false;
        }
      );
    }
    else if (_type === "date") {
      shape[_key] = Yup.mixed().test('match', `${_key} is required`, function (date) {
        return moment(date, 'YYYY-MM-DD').isValid();
      })
    }
    else {
      shape[_key] = Yup.string();
      if (_min)
        shape[_key] = shape[_key].min(
          _min,
          "Minimum length should be" + _min + ""
        );
      if (_max)
        shape[_key] = shape[_key].max(
          _max,
          `Maximum length of ${_key} should be ${_max}`
        );
      if (_matches)
        shape[_key] = shape[_key].matches(
          _matches,
          _err_msg || "Invalid value"
        );
    }
    if (_required) {
      shape[_key] = shape[_key].required(
        _req_msg || "The field " + _key.split('_').join(' ') + " is required"
      );
    }
    if (_key === "cpassword") {
      shape["cpassword"] = Yup.mixed().test('match', 'password and re-enter password does not match', function (password) {
        return password === this.parent.password
      })
    }
  }

  return Yup.object().shape(shape);
};

export const password_conditions = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

export const verifyPassword = (value: string): string => {
  if (
    value.length < 8 ||
    !/[a-z]/.test(value) ||
    !/[A-Z]/.test(value) ||
    !/[0-9]/.test(value) ||
    !/[!@#\$%\^&\*]/.test(value)
  ) {
    return "Password should contain : \n Minimum length of 8 characters \n Atleast 1 Numeric character \n Atleast 1 Uppercase letter \n Atleast 1 Lowercase letter \n Atleast 1 Special character";
  }

  return "";
};

export const createInitialValues = (fields: any) => {
  let initialValues: any = {};
  fields &&
    fields.map((field: any) => {
      if (field.type === "CheckBox") {
        field.options.map((option: any) => {
          initialValues[option.name] = option.value;
        });
      } else {
        initialValues[field.name] = field.value;
      }
    });
  return initialValues;
};
