import React, { useState } from "react";
import "./index.scss";
import { TextField, InputAdornment, FormControl } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
interface Form {
  errors: Object;
  touched: Object;
}

interface Props {
  icon?: any;
  variant: string;
  className: string;
  inline: boolean;
  labelText: string;
  labelPositionLeft: Boolean;
  labelCss: string;
  form: any;
  field: any;
  value: string | number;
  type?: string;
  create_err_msg?: any;
}

const iconComponentProps = (
  icon: any,
  { type, min_date, max_date, displayable }: any,
  showPassword?: boolean,
  onClick?: any
): any => ({
  InputProps: {
    startAdornment: icon ? (
      <InputAdornment
        position="start"
        className="visibility-icon"
        onClick={onClick}
      >
        {icon}
      </InputAdornment>
    ) : null,
    endAdornment: type === "password" && displayable
      ? (
        <InputAdornment position="end" className="visibility-icon">
          {!showPassword ? (
            <Visibility onClick={onClick} />
          ) : (
            <VisibilityOffIcon onClick={onClick} />
          )}
        </InputAdornment>
      ) : null
  }
});


const InputC: React.FC<Props> = React.memo(
  ({
    icon,
    variant,
    className,
    labelCss,
    labelPositionLeft,
    form,
    field,
    ...rest
  }) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePassword = (): any => {
      setShowPassword(!showPassword);
    };

    const parseNumber = () => { };
    const variantCss =
      variant === "tripStarter"
        ? "primary"
        : variant === "Outlined"
          ? "outlined"
          : variant === "Dark"
            ? "dark-outlined"
            : "outlined";
    return (
      <>
        <div className={labelPositionLeft && "flex-box-input"}>
          <div className={`label-text ${labelCss}`}>{rest.labelText}</div>
          <TextField
            fullWidth
            className={`${variantCss} ${className}`}
            {...field}
            {...rest}
            {...iconComponentProps(icon, rest, showPassword, togglePassword)}
            type={rest.type === "password" && showPassword ? "text" : rest.type}
            value={field && field.value ? field.value : rest.value}
          />
        </div>
        {form && form.errors[field.name] && form.touched[field.name] && (
          <div className="error-text">
            {rest.create_err_msg
              ? rest.create_err_msg(field.value)
              : form.errors[field.name]}
            {rest.create_err_msg && field.name === "cpassword" && (
              <p>{form.errors[field.name]}</p>
            )}
          </div>
        )}
      </>
    );
  }
);

export default InputC;
