import React, { Component } from "react";
import DataTable from "../../../components/table";
import { ActionPara, Data, HeadCell } from "../../../components/table/type";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import Button from "../../../components/button";
import Loader from "../../../components/loader";
import { parseAirlines } from "../utils";
import { deleteAirlineAPIs, getAllAirlineAPIs } from "../../../services/api-services/airlines";
import { notifError, notifSuccess } from "../../util";

const headCells: HeadCell[] = [
    { name: 'name', label: 'Airline Name' },
    { name: 'code', label: 'Airline Code' },
    { name: 'status', label: 'Status' },
    { name: "action", label: "Action" }
];
export type AirlineType = {
    id?: number;
    "name": string;
    "code": string;
    "status": boolean;
    action?: ActionPara[];
}

class Tags extends Component<RouteComponentProps, {
    loading: boolean;
    airlines: AirlineType[];
}> {
    state = {
        loading: false,
        airlines: [],
    }

    async componentDidMount() {
        this.setState({ loading: true })
        let res = await getAllAirlineAPIs()
        if (res)
            this.setState({ loading: false, airlines: parseAirlines(res.data, this.props.history) })
        else {
            notifError("Airline", "something went wrong.")
            this.setState({ loading: false })
        }
    }

    onDeleteTag = async (ids: string[]) => {
        let { airlines } = this.state;
        this.setState({ loading: true })
        for (let id of ids) {
            let res = await deleteAirlineAPIs(id);
            if (res.status) {
                let deleted: any = airlines.find((item: any) => item.id.toString() == id);
                airlines = airlines.filter((item: any) => item.id.toString() !== id);
                notifSuccess("Airline", `${deleted?.name} Airline has been deleted successfully.`)
            } else notifError("Airline", "something went wrong")
        }
        this.setState({ loading: false, airlines })
    };
    render() {
        return (
            <div className="root-container">
                <Loader show={this.state.loading} />
                <DataTable
                    onDelete={this.onDeleteTag}
                    title={"Airlines"}
                    headers={headCells}
                    data={this.state.airlines}
                >
                    <Link to="/filters/airlines/add">
                        <Button variant="contained" btnText={"+  Airline"} onClick={() => { }} />
                    </Link>
                </DataTable>
            </div>
        );
    }
}

export default withRouter(Tags);