import { RelatedTagType } from ".";
import { getTagsAPI } from "../../services/api-services/tags";

const getTagsFromDb = (q: string): Promise<RelatedTagType[]> => {
    return new Promise((resolve, rejects) => {
        var objects: RelatedTagType[] = []
        try {
            var request = indexedDB.open("tags"); // first step is opening the database
            request.onsuccess = function (e: any) {
                try {
                    var db = e.target.result;
                    var trans = db.transaction(["tags"], 'readwrite'); //second step is opening the object store
                    var store = trans.objectStore("tags");
                    var request = store.getAll(); //getting single object by id from object store

                    request.onsuccess = function (e: any) {
                        var cursor = e.target.result;
                        if (cursor)
                            resolve(cursor.filter((tag: RelatedTagType) => tag.name.toUpperCase().includes(q.toUpperCase()) || tag.id.toString().toUpperCase().includes(q.toUpperCase())))

                    }
                    request.onerror = function (e: any) {
                        resolve(objects);
                    };
                }catch(err){
                    resolve(objects)
                }
                

            };
            request.onerror = function (e: any) {
                resolve(objects);
            };
        } catch (err) {
            resolve(objects)
        }

    })
}

export {
    getTagsFromDb
}