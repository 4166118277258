import React, { Component } from "react";
import DataTable from "../../../components/table";
import { HeadCell } from "../../../components/table/type";
import "./style.scss";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import Loader from "../../../components/loader";
import { parseBooking } from "./utils";
import { notifError, notifSuccess } from "../../util";
import { getUserBookingAPIs } from "../../../services/api-services/users";
import { Button } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

const headCells: HeadCell[] = [
    { name: 'id', label: 'Id' },
    { name: 'departureDate', label: 'Departure' },
    { name: 'returnDate', label: 'Return' },
    { name: 'bookingId', label: 'Booking Id' },
    { name: 'bookingStatus', label: 'Booking Status' },
    { name: 'action', label: 'Action' },
];
export type UserBookingType = {
    bookingId: string;
    bookingStatus: string;
    departureDate: string;
    id: number | string;
    returnDate: string;
    user_id: string;
    action?: any;
    flightDetail: string;
}
const BOOKING: UserBookingType[] = []

class UserBooking extends Component<RouteComponentProps, {
    loading: boolean;
    booking: UserBookingType[];
}> {
    state = {
        booking: [],
        loading: false
    }
    updateTrips = (id: string) => {
        const { booking } = this.state;
        this.setState({ booking: [] })
        const trips = booking.filter(({ bookingId }: UserBookingType) => bookingId !== id)
        this.setState({ booking: trips })
    }

    async componentDidMount() {
        this.setState({ loading: true })
        const { id }: any = this.props.match.params;
        let res = await getUserBookingAPIs(id)
        console.log("res", res)
        if (res.status)
            this.setState({ loading: false, booking: parseBooking(res.data, this.props.history, this.updateTrips) })
        else {
            notifError("Tags", "something went wrong.")
            this.setState({ loading: false })
        }
    }

    render() {
        const { booking, loading } = this.state;
        return (
            <div className="root-container">
                <Loader show={loading} />
                <Button
                    className="back-btn"
                    onClick={() => {
                        this.props.history.push("/users");
                    }}
                >
                    <ArrowBack /> Back
				</Button>
                <DataTable
                    title={"Booking"}
                    headers={headCells}
                    data={booking}
                >
                    <></>
                </DataTable>
            </div>
        );
    }
}

export default withRouter(UserBooking);