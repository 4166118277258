import React from "react";
import "./index.scss"


interface Props {
  btnText: string;
  variant: string;
  className?: any;
  onClick: () => void;
}

const Button: React.FC<Props> = ({ btnText, onClick, variant,className }) => {
  const variantCss =
  variant === "contained" ? "filled-button " : "transparent-button";
  return (
    <button
      className={`${variantCss} ${className}`}
      type="submit"
      onClick={onClick}
    >
      {btnText}
    </button>
  );
};

export default Button;
