import Axios from "axios";
import { PlansType } from "../../../routes/plans";
import auth from "../../auth";
import { ResponseAPI, TagParams } from "../type";
import { API_URL, handleError } from "../utils";


const addPlanAPIs = async (data: PlansType): Promise<ResponseAPI> => {
    try {
        let resp = await Axios({
            method: "POST",
            data,
            url: `${API_URL}/plans/create`,
            headers: {
                Authorization: auth.getToken()
            }
        })
        return { status: true, data: resp.data };
    } catch (error) {
        return handleError(error)
    }
}

const updatePlanAPIs = async (data: PlansType, id: number): Promise<ResponseAPI> => {
    try {
        let resp = await Axios({
            method: "PUT",
            data,
            url: `${API_URL}/plans/update/${id}`,
            headers: {
                Authorization: auth.getToken()
            }
        })
        return { status: true, data: resp.data };
    } catch (error) {
        return handleError(error)
    }
}

const getAllPlanAPIs = async (): Promise<ResponseAPI> => {
    try {
        let resp = await Axios({
            method: "GET",
            url: `${API_URL}/plans/all`,
            headers: {
                Authorization: auth.getToken()
            }
        })
        return { status: true, data: resp.data };
    } catch (error) {
        return handleError(error)
    }
}
async function deletePlanAPIs(id: string) {
    try {
        let resp = await Axios({
            method: "GET",
            url: `${API_URL}/plans/delete/${id}`,
            headers: {
                Authorization: auth.getToken()
            }
        })
        return { status: true, data: resp.data };
    } catch (error) {
        return handleError(error)
    }
}


export {
    addPlanAPIs,
    getAllPlanAPIs,
    updatePlanAPIs,
    deletePlanAPIs
}