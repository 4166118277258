import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

interface DialogBoxProps {
    open: boolean;
    handleAccept: (sts?: boolean) => void;
    title: string;
    des: string;
    btnTxt: {
        accept: string;
        cancel: string;
    }
}
const DialogBox: React.FC<DialogBoxProps> = ({ open, handleAccept, des, title, btnTxt: { accept, cancel } }) => {

    const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            // fullScreen={fullScreen}
            open={open}
            // onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{des}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleAccept.bind({}, false)} color="primary">
                    {cancel}
                </Button>
                <Button onClick={handleAccept.bind({}, true)} color="primary" autoFocus>
                    {accept}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogBox;