import { UserPlanType } from ".";
import { ActionPara, Data } from "../../../components/table/type";
import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Backdrop, Modal, Fade } from '@material-ui/core';
import DialogBox from "../../../components/dialog-box";
import "./style.scss";
import { Close, Visibility, Edit } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: '0px solid #000',
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
	}),
);

const MoreView: React.FC<{ plan: UserPlanType }> = ({ plan }) => {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<div title="view">
				<Visibility className={"view"} onClick={handleOpen} />
			</div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={open}>
					<div className={classes.paper} id="view-more">
						<Close onClick={handleClose} className="close" />
						<h2 id="transition-modal-title">Plan Details</h2>
						<div>
							{Object.entries(plan).map(([key, value]: any, index: number) => (
								value !== null && (<div className="content">
									<div className="key-txt">{key.replace("_", " ")}</div>
									{key === "status" ? value ?
										<div className="status active">Active</div> :
										<div className="status deactive">Deactive</div> :
										<div>{value}</div>
									}
								</div>)))}
						</div>
					</div>
				</Fade>
			</Modal>
		</div>
	);
}
const DeactivePlan: React.FC<{ updatePlan: any; }> = ({ updatePlan }) => {

	const [open, setOpen] = React.useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleAccept = async (sts: boolean | undefined) => {
		setOpen(false);
		if (sts) updatePlan()
	};
	return (
		<div>
			<div title="Deactive" className="status active" onClick={handleOpen}>Active</div>
			<DialogBox
				open={open}
				btnTxt={{ cancel: "No", accept: "Yes" }}
				title={`Deactive Plan`}
				des={`Are you sure you want to deactive the plan?`}
				handleAccept={handleAccept}
			/>
		</div>
	);
}



const getActions = (history: any, plan: UserPlanType): ActionPara[] => {
	const actions: ActionPara[] = [
		{
			name: "View",
			component: <MoreView plan={plan} />
		}
	];
	// if (plan.status) {
	// 	actions.push({
	// 		name: "Update",
	// 		component:
	// 			<div title={"Update"}>
	// 				<Edit className="view" onClick={
	// 					() => history.push({
	// 						pathname: `/users/plans/${plan.user_id}/update/${plan.id}`,
	// 						state: plan
	// 					})}
	// 				/>
	// 			</div>
	// 	})
	// }
	return actions;
}
const getStatus = (status: boolean, updatePlan: any) => ({
	component: <>
		{status ?
			<DeactivePlan updatePlan={updatePlan} /> :
			<div className="status deactive">Deactive</div>}
	</>
})
const parsePlans = (plans: UserPlanType[], updatePlans: any, history: any): UserPlanType[] => {
	let parse = plans.map(
		(plan: UserPlanType) => ({
			...plan,
			status: getStatus(plan.status, () => updatePlans(plan.id)),
			action: getActions(history, plan),
		})
	);
	return parse;
};

export { parsePlans };
