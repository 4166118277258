import { PlansType } from ".";
import { AirportType } from "../../components/select-airports";
import { ActionPara, Data } from "../../components/table/type";

type AirportRes = {
	name: string;
	id: string;
	image: string;
	airports: AirportType[];
	status: boolean;
};
const getActions = (history: any, plan: PlansType): ActionPara[] => [
	{
		name: "Edit",
		action: () => history.push({
			pathname: "/plans/edit/" + plan.id,
			state: plan,
		})
	},
];

const parsePlans = (plans: PlansType[], history: any): PlansType[] => {
	let parse = plans.map(
		({ id, ...rest }: PlansType) => ({
			id, ...rest,
			action: getActions(history, { id, ...rest }),
		})
	);
	return parse;
};

export { parsePlans };
