import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import "./index.scss";
import { OptionType } from "../../FormComponents/types";

interface Props {
  labelText: string;
  options: OptionType[];
  form: any;
  field: any;
  value: string;
  labelCss: string;
  labelPositionLeft: Boolean;
  inLineCheckBox: Boolean;
}

const RadioList: React.FC<Props> = React.memo(
  ({
    inLineCheckBox,
    labelCss,
    labelPositionLeft,
    value,
    options,
    form,
    field,
    ...rest
  }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (form) {
        form.setFieldValue(event.target.name, event.target.checked);
      }
    };
    const renderRadio = (option: OptionType, index: number) => (
      <div className="checkBox">
        <Checkbox
          key={index}
          name={field.name}
          checked={form && form.values[option.id]}
          onChange={handleChange}
        />
        <div>{option.label}</div>
      </div>
    );
    return (
      <>
        <div className={labelPositionLeft && "flex-box-check"}>
          <div
            className={`label-text-check ${labelCss} ${labelPositionLeft && "margin-label-check"
              }`}
          >
            {rest.labelText}
          </div>
          <div className={inLineCheckBox && "flex-box-check"}>
            {options && options.map(renderRadio)}
          </div>
        </div>
        {form && form.errors[field.name] && form.touched[field.name] && (
          <div className="error-text-checkbox">{form.errors[field.name]}</div>
        )}
      </>
    );
  }
);

export default RadioList;
