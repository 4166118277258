import React, { useState, useEffect } from "react";
import { Input, Select } from "@material-ui/core";
import "./index.scss";
import { OptionType } from "../types";
import { fields } from "../../../routes/tags/fields";

const DropDownIcon = () => <svg width="20" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0.99086 0.665273C0.664193 0.991939 0.664193 1.51861 0.99086 1.84527L6.53086 7.38527C6.79086 7.64527 7.21086 7.64527 7.47086 7.38527L13.0109 1.84527C13.3375 1.51861 13.3375 0.99194 13.0109 0.665273C12.6842 0.338607 12.1575 0.338607 11.8309 0.665273L6.99753 5.49194L2.16419 0.658606C1.84419 0.338606 1.31086 0.338606 0.99086 0.665273Z" fill="white" />
</svg>

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



export default function MultipleSelect({
  options,
  multiple,
  icon,
  placeholder,
  field: { name, value },
  form: { values, setFieldValue, touched, errors, handleChange },
  labelPositionLeft,
  labelCss,
  disable = false,
  ...rest
}: any) {

  return (
    <>
      <div className={`${labelPositionLeft && "flex-box-selection"} primary`}>
        <div className={`label-text-selection ${labelCss}`}>
          {rest.labelText}
        </div>
        {icon && <div className='icon-root'>
          {icon}
        </div>}

        <Select
          native
          disabled={disable}
          required
          fullWidth
          labelId="demo-mutiple-name-label"
          displayEmpty
          className={`MuiInput-root ${!icon && "p-l-0"}`}
          id="demo-mutiple-name"
          value={value}
          onChange={handleChange(name)}
          IconComponent={DropDownIcon}
          input={<Input />}
          MenuProps={MenuProps}
          renderValue={(selected: any) => selected || placeholder}
        >
          {options &&
            options.map(({ label, id }: OptionType) => (
              <option
                key={id}
                value={id}
              >
                {label}
              </option>
            ))}
        </Select>
      </div>
      {errors[name] && touched[name] && (
        <div className="error-text">
          {rest.create_err_msg ? rest.create_err_msg(value) : errors[name]}
        </div>
      )}
    </>
  );
}
