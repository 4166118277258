import React from "react";
import { Formik, Form, Field } from "formik";
import { createSchema, createInitialValues } from "./utils";
import { formProps, formParams } from "./types";
import { Button, Grid } from "@material-ui/core";
import Sections from "../sections";
import { field as field_type } from "./types";
import Selection from "./Selection";
import CustomField from "../FieldComponent";
import RadioButton from "../FieldComponent/radioField";
import CheckBoxComponent from "../FieldComponent/CheckBox";
import { Link } from "react-router-dom";
import DatePicker from "./date-picker";
import FullName from "../FieldComponent/full-name";
import ContactField from "./contactField";


class FormContainer extends React.PureComponent<formProps, {
}> {
	schema = createSchema(this.props.fields);

	renderField = (props: field_type) => {
		if (!props.component) {
			if (props.type === "radioButton")
				props.component = RadioButton;
			else if (props.type === "CheckBox")
				props.component = CheckBoxComponent;
			else if (props.type === "select")
				props.component = Selection;
			else if (props.type === "date")
				props.component = DatePicker;
			else if (props.type === "full_name")
				props.component = FullName;
			else if (props.type === "tel") props.component = ContactField;

			else
				props.component = CustomField;
		}
		return (
			<Grid item xs={props.grid || 12}>
				<Field key={props.name} {...props} />
			</Grid>);
	};

	renderForm = ({ handleSubmit }: formParams) => {
		const {
			fields,
			renderCustomSubmit,
			sectionWrap,
			forgotPassword,
			btnText,
		}: formProps = this.props;
		return (
			<Form onSubmit={handleSubmit}>
				{sectionWrap ? (
					<Sections>
						<Grid container spacing={1}>
							{fields.map(this.renderField)}
						</Grid>
						{renderCustomSubmit || (
							<Button variant="contained" type="submit">
								{btnText}
							</Button>
						)}
						<Link to="/">
							<Button variant="contained" color="primary">
								Cancel
							</Button>
						</Link>
					</Sections>
				) : (
					<div>
						<Grid container>
							{this.props.fields.map(this.renderField)}
						</Grid>
						{renderCustomSubmit || (
							<Button variant="contained" type="submit">
								{btnText}
							</Button>
						)}
					</div>
				)}
			</Form>
		);
	};

	render() {
		let { onSubmit, maxWidth, initialValues }: any = this.props;
		return (
			<div style={{ maxWidth: maxWidth || "auto" }}>
				<Formik
					enableReinitialize
					initialValues={initialValues}
					onSubmit={onSubmit}
					validationSchema={this.schema}
				>
					{this.renderForm}
				</Formik>
			</div>
		);
	}
}

export default FormContainer;
