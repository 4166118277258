import React, { Component } from "react";
import Form from "../../../components/FormComponents";
import { fields } from "./fields"
import RightPannel from "../../../components/Authentication/right-pannel";
import Button from "../../../components/button";
import Loader from "../../../components/loader";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { notifError, notifSuccess } from "../../util";
import { AirlineType } from ".";
import { addAirlineAPIs } from "../../../services/api-services/airlines";
interface StateParams {
    loading: boolean;
}

function parsePayload(payload: any): AirlineType {
    return {
        name: payload["Airline Name"],
        code: payload["Airline Code"],
        status: payload.status === "true" ? true : false
    }
}
const initialValues = { status: "true" }
class Add extends Component<RouteComponentProps, StateParams> {
    state = {
        loading: false,
    }
    onSubmit = async (payload: any, resetForm: any) => {
        console.log("PAYLOAD: ", payload)
        this.setState({ loading: true })
        let res = await addAirlineAPIs(parsePayload(payload))
        if (res.status) {
            notifSuccess("Airline", "Airline has been added successfully.")
            this.props.history.push("/filters/airlines")
        }
        else notifError("Airline", `something went wrong`)
        this.setState({ loading: false })
    }
    handleCancel = () => {
        this.props.history.push("/filters/airlines")
    }
    render() {
        return (
            <div className="root-container form-center">
                <Loader show={this.state.loading} />
                <div className="flexBox-root">
                    <div id="add-tags">
                        <RightPannel
                            className="rightPanel-container"
                            from={0} handleChange={() => { }}>
                            <div className="rightPanel">
                                <div className="title">Add New Airline</div>
                                <div className="line"></div>
                                <div className="form">
                                    <Form
                                        fields={fields}
                                        onSubmit={this.onSubmit}
                                        initialValues={initialValues}
                                        btnText={"Add"}
                                        renderCustomSubmit={
                                            <div className="bottomSection">
                                                <div className="forgotPassWord"></div>
                                                <Button onClick={this.handleCancel} btnText={"Cancel"} variant={"contained"} />
                                                <Button onClick={() => { }} btnText={"Add"} variant={"contained"} />
                                            </div>
                                        }
                                    />

                                </div>
                            </div>
                        </RightPannel>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Add);