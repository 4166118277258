import { Image, Bookmark } from "@material-ui/icons";
import { field } from "../../components/FormComponents/types";

const fields: field[] = [
    {
        name: "name",
        placeholder: "Tag Name",
        type: "text",
        icon: <Bookmark />,
        required: true,
        fieldType: "tripStarter",
        variant: "tripStarter",
    },
    {
        name: "status",
        labelText: "Status",
        placeholder: "Hide/Show",
        type: "radioButton",
        options: [
            { label: "Show", id: "true", },
            { label: "Hide", id: "false", }
        ],
        required: true,
        value: "true",
        fieldType: "tripStarter",
        variant: "tripStarter",
    },
    // {
    //     name: "img",
    //     placeholder: "Image",
    //     type: "file",
    //     required: true,
    //     create_err_msg: validateImage,
    //     icon: <Image />,
    //     displayable: true,
    //     fieldType: "tripStarter",
    //     variant: "tripStarter",
    // },
];


export {
    fields,
}