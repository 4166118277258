import Amplify, { Auth } from "aws-amplify";
import { Authentication } from "../interfaces";
import { AwsCognitoConfig, Response } from "../types";

const parseErrorCode = (code: string) => {
  switch (code) {
    case "UsernameExistsException":
      return "User already exists with this email";
    case "LimitExceededException":
      return "Attempt limit exceeded, please try after some time.";
    case "CodeMismatchException":
      return "Invalid code provided. Please request a new code again."
    case "InvalidPasswordException":
      return "Resetting password character requirements should match new password requirements."
    case "InvalidParameterException":
      return "Invalid phone number format."
    default:
      return "Something went wrong";
  }
};

export class AwsCognitoService implements Authentication {
  constructor(config: AwsCognitoConfig) {
    try {
      Amplify.configure(config);
    }
    catch (err) {
      console.log("err", err);
    }
  }
  async doLogin(email: string, password: string): Promise<Response> {
    try {
      const resp = await Auth.signIn(email, password);
      console.log("resp------", resp);
      return {
        data: resp.signInUserSession.idToken.jwtToken,
      };
    } catch (error) {
      console.log("error------", error);
      return {
        error: error.code,
        data: error,
        message: error.message,
      };
    }
  }

  async getUser(): Promise<{
    email?: string;
    firstName?: string;
    error?: boolean;
    data?: any;
    message?: string;
  }> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return { ...user.attributes, ...user.signInUserSession.accessToken };
    } catch (error) {
      return {
        error: true,
        data: error,
        message: parseErrorCode(error.code),
      };
    }
  }

  async changePassword(
    email: string,
    old_pass: string,
    new_pass: string
  ): Promise<any> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const resp = await Auth.changePassword(user, old_pass, new_pass);
      return {
        data: resp,
      };
    } catch (error) {
      return {
        error: true,
        data: error,
        message: parseErrorCode(error.code),
      };
    }
  }

  async logout(): Promise<boolean> {
    console.log("logout()");
    await Auth.signOut();
    return true;
  }
}
