import { AirportType } from ".";
import AIRPORTS from "./airports_with_ids.json";

const airports: any = AIRPORTS;

const filterAirports = (q: string): AirportType[] => (
    airports.filter(({ iataCode, icaoCode, name, countryCode, city }: AirportType) =>
        iataCode?.toUpperCase().includes(q.toUpperCase()) ||
        icaoCode?.toUpperCase().includes(q.toUpperCase()) ||
        name?.toUpperCase().includes(q.toUpperCase()) ||
        countryCode?.toUpperCase().includes(q.toUpperCase()) ||
        city?.toUpperCase().includes(q.toUpperCase())
    )
)

const getObjectsFromIndexDB = (dbName: string, ids: number[]): Promise<any[]> => {
    return new Promise((resolve, rejects) => {
        const objects: any = [];
        try {
            var request = indexedDB.open(dbName); // first step is opening the database
            request.onsuccess = function (e: any) {
                var db = e.target.result;
                try {
                    var trans = db.transaction([dbName], 'readwrite'); //second step is opening the object store
                    trans.oncomplete = function (event: any) {
                        console.log("All done!");
                        db.close()
                        resolve(objects);
                    }
                    trans.onerror = function (event: any) {
                        console.log("All done!");
                        db.close()
                        resolve(objects);
                    }

                    var store = trans.objectStore(dbName);
                    for (const id of ids) {
                        var request = store.get(id); //getting single object by id from object store

                        request.onsuccess = function (e: any) {
                            let cursor = e.target.result;
                            if (cursor)
                                objects.push(cursor)
                        };
                        request.onerror = function (e: any) {
                            console.log("Error Getting: ", e);
                        };
                    }
                } catch (err) {
                    console.log(err, ids)
                    if (dbName === "airports") {
                        for (const id of ids) {
                            let air = airports.filter((item: AirportType) => Number(item.id) === Number(id))
                            if (air) objects.push(air[0])

                        }
                    }
                    resolve(objects)

                }

            };
            request.onerror = function (e: any) {
                console.log("Error Getting: ", e);
                resolve(objects)
            };
        } catch (error) {
            console.log("-----------error----------", error)
            if (dbName === "airports") {
                for (const id of ids) {
                    let air = airports.filter((item: AirportType) => Number(item.id) === Number(id))
                    if (air) objects.push(air[0])

                }
            }
            resolve(objects)

        }
    })


}
export {
    filterAirports,
    getObjectsFromIndexDB
}