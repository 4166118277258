import { Wc, PermIdentity, MailOutline, DateRange, Payment } from "@material-ui/icons";
import { field } from "../../components/FormComponents/types";
import COUNTRIES from "./country-nationality-list.json";

const IdTypeIcon = () => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.64232 5.01784L9.22565 1.55117C9.70898 1.29284 10.2923 1.29284 10.7757 1.55117L17.359 5.01784C17.7007 5.19284 17.9173 5.55117 17.9173 5.93451C17.9173 6.50951 17.4507 6.97617 16.884 6.97617H3.12565C2.55065 6.97617 2.08398 6.50951 2.08398 5.93451C2.08398 5.55117 2.30065 5.19284 2.64232 5.01784ZM3.75065 9.89284V13.2262C3.75065 13.9178 4.30898 14.4762 5.00065 14.4762C5.69232 14.4762 6.25065 13.9178 6.25065 13.2262V9.89284C6.25065 9.20117 5.69232 8.64284 5.00065 8.64284C4.30898 8.64284 3.75065 9.20117 3.75065 9.89284ZM8.75065 9.89284V13.2262C8.75065 13.9178 9.30898 14.4762 10.0007 14.4762C10.6923 14.4762 11.2507 13.9178 11.2507 13.2262V9.89284C11.2507 9.20117 10.6923 8.64284 10.0007 8.64284C9.30898 8.64284 8.75065 9.20117 8.75065 9.89284ZM17.9173 17.3928C17.9173 18.0845 17.359 18.6428 16.6673 18.6428H3.33398C2.64232 18.6428 2.08398 18.0845 2.08398 17.3928C2.08398 16.7012 2.64232 16.1428 3.33398 16.1428H16.6673C17.359 16.1428 17.9173 16.7012 17.9173 17.3928ZM13.7507 9.89284V13.2262C13.7507 13.9178 14.309 14.4762 15.0007 14.4762C15.6923 14.4762 16.2507 13.9178 16.2507 13.2262V9.89284C16.2507 9.20117 15.6923 8.64284 15.0007 8.64284C14.309 8.64284 13.7507 9.20117 13.7507 9.89284Z" fill="white" />
</svg>

const fields: field[] = [
    {
        name: "name",
        placeholder: "First name | Middle name | Last name",
        type: "full_name",
        icon: <PermIdentity />,
        required: true,
        fieldType: "tripStarter",
        variant: "tripStarter",
        max: 25
    },
    {
        name: "gender",
        placeholder: "Gender",
        type: "select",
        default: "male",
        // icon: <Wc style={{ color: "white" }} />,
        options: [
            {
                label: "Male",
                id: "M",
            },
            {
                label: "Female",
                id: "F",
            },
            {
                label: "Others",
                id: "O",
            },
        ],
        required: true,
        fieldType: "tripStarter",
        variant: "tripStarter",
        grid: 6,
    },
    {
        name: "Date of birth",
        placeholder: "Date of birth",
        type: "date",
        required: true,
        icon: <DateRange style={{ color: "white" }} />,
        displayable: true,
        fieldType: "tripStarter",
        variant: "tripStarter",
        grid: 6,
        maxDate: new Date(Date.now() - 24 * 60 * 60 * 1000),
    },
    {
        name: "email",
        placeholder: "Email",
        type: "email",
        icon: <MailOutline />,
        required: true,
        fieldType: "tripStarter",
        variant: "tripStarter",
    },
    {
        name: "nationality",
        placeholder: "Nationality",
        type: "select",
        // icon: <Wc style={{ color: "white" }} />,
        options: COUNTRIES,
        required: true,
        fieldType: "tripStarter",
        variant: "tripStarter",
        grid: 6,
    },
    {
        defaultValue: "us",
        name: "Mobile No",
        placeholder: "Mobile No",
        type: "tel",
        required: true,
        fieldType: "tripStarter",
        variant: "tripStarter",
        grid: 6,
    },

    {
        name: "ID type",
        placeholder: "ID type",
        type: "select",
        options: [
            {
                label: "Passport",
                id: "Passport",
            },
        ],
        required: true,
        icon: <IdTypeIcon />,
        displayable: true,
        fieldType: "tripStarter",
        variant: "tripStarter",
        grid: 6,
    },
    {
        name: "ID expiry",
        placeholder: "ID expiry",
        type: "date",
        required: true,
        icon: <DateRange style={{ color: "white" }} />,
        displayable: true,
        fieldType: "tripStarter",
        variant: "tripStarter",
        grid: 6,
        minDate: new Date(Date.now() + 24 * 60 * 60 * 1000),
    },
    {
        name: "ID number",
        placeholder: "ID number",
        type: "text",
        max: 10,
        required: true,
        icon: <Payment />,
        displayable: true,
        fieldType: "tripStarter",
        variant: "tripStarter",
        grid: 12,
    },
];

export {
    fields
}