import Axios from "axios";
import { AirlineType } from "../../../routes/filters/airlines";
import auth from "../../auth";
import { ResponseAPI } from "../type";
import { API_URL, handleError } from "../utils";


const addAirlineAPIs = async (data: AirlineType): Promise<ResponseAPI> => {
    try {
        let resp = await Axios({
            method: "POST",
            data,
            url: `${API_URL}/filters/airlines/add`,
            headers: {
                Authorization: auth.getToken()
            }
        })
        return { status: true, data: resp.data };
    } catch (error) {
        return handleError(error)
    }
}

const updateAirlineAPIs = async (data: any, id: number): Promise<ResponseAPI> => {
    try {
        let resp = await Axios({
            method: "PUT",
            data,
            url: `${API_URL}/filters/airlines/update/${id}`,
            headers: {
                Authorization: auth.getToken()
            }
        })
        return { status: true, data: resp.data };
    } catch (error) {
        return handleError(error)
    }
}

const getAllAirlineAPIs = async (): Promise<ResponseAPI> => {
    try {
        let resp = await Axios({
            method: "GET",
            url: `${API_URL}/filters/airlines`,
            headers: {
                Authorization: auth.getToken()
            }
        })
        return { status: true, data: resp.data };
    } catch (error) {
        return handleError(error)
    }
}
const deleteAirlineAPIs = async (id: string): Promise<ResponseAPI> => {
    try {
        let resp = await Axios({
            method: "DELETE",
            url: `${API_URL}/filters/airlines/delete/${id}`,
            headers: {
                Authorization: auth.getToken()
            }
        })
        return { status: true, data: resp.data };
    } catch (error) {
        return handleError(error)
    }
}



export {
    addAirlineAPIs,
    updateAirlineAPIs,
    getAllAirlineAPIs,
    deleteAirlineAPIs
}