import { FlightTakeoff, AirlineSeatReclineExtra } from "@material-ui/icons";
import { field } from "../../../components/FormComponents/types";

const fields: field[] = [
    {
        name: "status",
        labelText: "Status",
        placeholder: "Hide/Show",
        type: "radioButton",
        options: [
            { label: "Show", id: "true" },
            { label: "Hide", id: "false" }
        ],
        required: true,
        value: "true",
        fieldType: "tripStarter",
        variant: "tripStarter",
    },
    {
        name: "Airline Name",
        placeholder: "Airline Name",
        type: "text",
        icon: <FlightTakeoff />,
        required: true,
        fieldType: "tripStarter",
        variant: "tripStarter",
    },
    {
        name: "Airline Code",
        placeholder: "Airline Code",
        type: "text",
        required: true,
        icon: <AirlineSeatReclineExtra />,
        fieldType: "tripStarter",
        variant: "tripStarter",
    },
];


export {
    fields,
}