import React, { Component } from "react";
import "./style.scss";
import Form from "../../components/FormComponents";
import { fields } from "./fields"
import RightPannel from "../../components/Authentication/right-pannel";
import Button from "../../components/button";
import Loader from "../../components/loader";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { notifError, notifSuccess } from "../util";
import { TravellerType } from ".";
import { parseDataReq, parseReqData } from "./util"
import { updateTravellerAPIs } from "../../services/api-services/users";


interface StateParams {
    loading: boolean;
    initialValues: TravellerType | {};
}

class UpdateTraveller extends Component<RouteComponentProps, StateParams> {
    state = {
        loading: true,
        initialValues: {},
    }
    componentDidMount() {
        const { state: traveller }: TravellerType | any = this.props.location;
        const { id = 0 }: any = this.props.match.params;
        if (traveller && id) {
            this.setState({ initialValues: parseDataReq(traveller), loading: false })
        } else if (id !== "") {
            notifError("Error", "Please select a valid traveller.")
            this.props.history.push("/users/travellers/" + id)
        } else {
            notifError("Error", "Please select a valid user.")
            this.props.history.push("/users")
        }
    }
    onSubmit = async (payload: any, resetForm: any) => {
        this.setState({ loading: true })
        const { id }: any = this.state.initialValues;
        const data = parseReqData(payload);
        const resp = await updateTravellerAPIs({ ...data, id })
        if (resp.status) {
            notifSuccess("Traveller", "Traveller updated successfully.")
            const { id: tId }: any = this.props.match.params;
            this.props.history.push("/users/travellers/" + tId)
        }
        else notifError("Traveller", `something went wrong`)
        this.setState({ loading: false })
    }
    handleCancel = () => {
        const { id = 0 }: any = this.props.match.params;
        if (id)
            this.props.history.push("/users/travellers/" + id)
        else
            this.props.history.goBack()
    }
    render() {
        const { initialValues, loading } = this.state;
        return (
            <div className="root-container form-center">
                <Loader show={loading} />
                <div className="flexBox-root">
                    <div id="add-tags">
                        <RightPannel
                            className="rightPanel-container"
                            from={0}
                            handleChange={() => { }}
                        >
                            <div className="rightPanel">
                                <div className="title">Edit Traveller Details</div>
                                <div className="line"></div>
                                <div className="form">
                                    <Form
                                        fields={fields}
                                        onSubmit={this.onSubmit}
                                        initialValues={initialValues}
                                        btnText={"Update"}
                                        renderCustomSubmit={
                                            <div className="bottomSection">
                                                <Button onClick={this.handleCancel} btnText={"Cancel"} variant={"contained"} />
                                                <Button onClick={() => { }} btnText={"Confirm"} variant={"contained"} />
                                            </div>
                                        }
                                    />

                                </div>
                            </div>
                        </RightPannel>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(UpdateTraveller);