import React, { Component } from "react";
import { TextField, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { LocalAirport, Close } from "@material-ui/icons"
import "./style.scss";
import { filterAirports } from "./filter-airports";

export type AirportType = {
    city: string;
    continent: string;
    countryCode: string;
    elevation: string;
    iataCode: string;
    icaoCode: string;
    id: string;
    latitude: string;
    longitude: string;
    name: string;
    region: string;
    type: string;
    country: string;

}

const isAirportExists = (airports: AirportType[], port: AirportType) => (
    airports.some(({ iataCode, icaoCode }: AirportType) => (
        iataCode.length && iataCode === port.iataCode) ||
        (icaoCode.length && icaoCode === port.icaoCode))
)

class SelectAirports extends Component<{
    addedAirports: AirportType[];
    handleAirports: (airports: AirportType[]) => void;
}, {
    airports: AirportType[];
}> {
    state = {
        airports: []
    }
    loadAirports = (e: any) => {
        const val = e.target.value;
        if (val.length > 2) {
            const airports = filterAirports(val);
            this.setState({ airports })
        }
    }

    searchAirport = (options: AirportType[], state: any) => (options)
    removeHandle = (index: number) => {
        let { addedAirports } = this.props;
        addedAirports.splice(index, 1)
        this.props.handleAirports(addedAirports)
    }

    handleSelect = (event: any, port: AirportType | null, reason: any) => {
        const { addedAirports } = this.props;
        if (port !== null && reason === "select-option") {
            const isExists = isAirportExists(addedAirports, port)
            if (!isExists)
                this.props.handleAirports([...addedAirports, port])
        }
    }
    renderAirports = (des: AirportType, index: number) => (
        <Grid key={index} xs={2} item>
            {console.log("des", des)}
            <div className="source-des-box">
                <Close onClick={this.removeHandle.bind(this, index)} className="close-icon" />
                <div className="name-overflow">{des.iataCode || des.icaoCode}</div>
            </div>
        </Grid>
    )
    renderOptions = ({ id, city, iataCode, icaoCode, name, country, countryCode, continent }: AirportType) => (
        <div title={`${city || iataCode || icaoCode}, ${name || iataCode || icaoCode}, ${country || countryCode || continent || "All"}`} className="auto-option" key={id}>
            <svg className="flight-icon" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.97242 0C5.10725 7.62939e-06 5.24071 0.0267801 5.36489 0.0787306C5.48907 0.130681 5.60145 0.206753 5.69537 0.30244C5.78928 0.398127 5.86283 0.511484 5.91164 0.635794C5.96046 0.760104 5.98355 0.892838 5.97955 1.02614L5.90921 3.39776L9.52427 5.12668C9.81477 5.26533 10 5.55716 10 5.87651C9.99998 5.94206 9.9859 6.00685 9.95869 6.06662C9.93149 6.12639 9.89178 6.17977 9.84218 6.22324C9.79258 6.26672 9.73423 6.29929 9.67096 6.31882C9.6077 6.33834 9.54097 6.34438 9.47518 6.33652L5.83481 5.90504L5.77914 7.78963L6.45782 8.0349C6.58345 8.08034 6.69194 8.16284 6.76861 8.27126C6.84528 8.37968 6.88643 8.50877 6.88648 8.64107C6.88648 8.70034 6.87467 8.75903 6.85172 8.81378C6.82878 8.86853 6.79514 8.91827 6.75275 8.96016C6.71035 9.00204 6.66002 9.03525 6.60464 9.05789C6.54926 9.08052 6.48991 9.09214 6.42998 9.09207H5.68298L5.59745 9.49752C5.56754 9.63953 5.48909 9.76701 5.3753 9.85853C5.26152 9.95004 5.11933 10 4.97267 10C4.82601 10 4.68383 9.95004 4.57004 9.85853C4.45625 9.76701 4.3778 9.63953 4.34789 9.49752L4.26287 9.09207H3.51485C3.28205 9.09207 3.09074 8.92038 3.0624 8.69764L3.05886 8.64107C3.05898 8.50892 3.1001 8.37998 3.17667 8.27167C3.25324 8.16336 3.36156 8.08089 3.48702 8.0354L4.16519 7.78913L4.10952 5.91155L0.524318 6.33652C0.515067 6.33763 0.505784 6.33846 0.496483 6.33902L0.468647 6.34002C0.210031 6.34002 0 6.13229 0 5.87651C0 5.58369 0.155372 5.31439 0.404879 5.16422L0.475733 5.12668L4.03614 3.42329L3.96528 1.02564C3.95752 0.761458 4.05617 0.505048 4.23953 0.312802C4.4229 0.120557 4.67596 0.00821972 4.94306 0.000499725L4.97242 0Z" />
            </svg>
            <b className="city extra-txt">{city || iataCode || icaoCode},</b>
            <div className="name extra-txt">{name || iataCode || icaoCode}</div>
        </div>
    )
    render() {
        const { addedAirports } = this.props;
        const { airports } = this.state;
        const defaultProps = {
            options: airports,
            getOptionLabel: (option: AirportType) => "",
        };
        const addedIds = addedAirports.map((airport: AirportType) => airport.id);
        return (
            <>
                <div id="added-airports">
                    <Grid container>
                        {addedAirports.map(this.renderAirports)}
                    </Grid>
                </div>
                <div id="select-airports">
                    <LocalAirport className="flight-icon" />
                    <Autocomplete
                        selectOnFocus={true}
                        renderOption={this.renderOptions}
                        onChange={this.handleSelect}
                        getOptionDisabled={(option: AirportType) => addedIds.includes(option.id)}
                        {...defaultProps}
                        filterSelectedOptions={true}
                        id="disable-close-on-select"
                        filterOptions={this.searchAirport}
                        renderInput={(params) => (
                            <TextField placeholder="Select Airport" onChange={this.loadAirports} className="form-input primary" {...params} margin="normal" />
                        )}
                    />
                </div>
            </>
        )
    }
}

export default SelectAirports;