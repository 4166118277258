
import axios from 'axios'
import { setupCache } from 'axios-cache-adapter'

export default class GoogleImageSearch {


    static searchImage(query) {
        query = encodeURIComponent(query)

         const cache = setupCache({
            maxAge: 15 * 60 * 1000
        })  

        const api = axios.create({
            adapter: cache.adapter
        })

        return new Promise( (resolve) => {

        api({
            url: 'https://google-image-app.herokuapp.com/search?str='+query,
            method: 'get'
            }).then(async (response) => {

                const list = response.data.result || []
                const urls = list.map(({ url }) => url)

                console.log('response.data.result :', urls)
                resolve(urls)
            })            
            .catch( err => resolve([]) )

        
        })
}

  
}
