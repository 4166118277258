import { AirportType } from "../../components/select-airports";
import { ActionPara, Data } from "../../components/table/type";
import { AirlineType } from "./airlines";

type AirportRes = {
	name: string;
	id: string;
	image: string;
	airports: AirportType[];
	status: boolean;
};
const getActions = (history: any, name: string, image: any, locationName: any, status: boolean): ActionPara[] => [
	{
		name: "Airports",
		action: () =>
			history.push({
				pathname: "/tags/airports/" + name,
				state: { image: image, name: locationName },
			}),
	},
	{
		name: "Edit",
		action: () => history.push({
			pathname: "/tags/edit/" + name,
			state: { image: image, name: locationName, status },
		})
	},
];

const parseTags = (tags: any, history: any): Data[] => {
	let parse = tags.map(
		({ name, image, id, status }: AirportRes) => ({
			name,
			airports: 0,
			img: image,
			id,
			status,
			action: getActions(history, id, image, name, status),
		})
	);
	return parse;
};

const getAirlineActions = (history: any, airline: AirlineType): ActionPara[] => [
	{
		name: "Edit",
		action: () => history.push({
			pathname: "/filters/airlines/" + airline.id,
			state: { ...airline },
		})
	},
];

const parseAirlines = (airlines: AirlineType[], history: any): AirlineType[] => {
	let parse = airlines.map(
		(airline: AirlineType) => ({
			...airline,
			action: getAirlineActions(history, airline),
		})
	);
	return parse;
};

export { parseTags, parseAirlines };
