import React, { Component } from "react";
import { TextField, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Bookmarks, Close } from "@material-ui/icons"
import "./style.scss";
import { getTagsFromDb } from "./utils";

export type RelatedTagType = {
    name: string;
    id: number;
}

const isTagsExists = (tags: RelatedTagType[], tag: RelatedTagType) => (
    tags.some(({ name, id }: RelatedTagType) => (
        (name === tag.name) ||
        (id === tag.id))
    )
)

class RelatedTags extends Component<{
    relatedTags: RelatedTagType[];
    handleRelatedTags: (tags: RelatedTagType[]) => void;
}, {
    tags: RelatedTagType[];
    loading: boolean;
}> {
    state = {
        tags: [],
        loading: false
    }

    loadTags = async (q: any) => {
        if (!this.state.loading) {
            this.setState({ loading: true })
            let tags = await getTagsFromDb(q.target.value);
            this.setState({ tags, loading: false })
        }
    }

    searchTags = (options: RelatedTagType[], state: any) => (options)
    removeHandle = (index: number) => {
        let { relatedTags } = this.props;
        relatedTags.splice(index, 1)
        this.props.handleRelatedTags(relatedTags)
    }

    handleSelect = (event: any, tag: RelatedTagType | null, reason: any) => {
        const { relatedTags } = this.props;
        if (tag !== null && reason === "select-option") {
            const isExists = isTagsExists(relatedTags, tag)
            if (!isExists)
                this.props.handleRelatedTags([...relatedTags, tag])
        }
    }
    renderTags = (tag: RelatedTagType, index: number) => (
        <Grid key={index} xs={2} item>
            <div className="source-des-box">
                <Close onClick={this.removeHandle.bind(this, index)} className="close-icon" />
                <div className="name-overflow">{tag.name || tag.id}</div>
            </div>
        </Grid>
    )
    renderOptions = (option: RelatedTagType) => (
        <div className="auto-option" key={option.id}>
            <b className="city">{option.id},</b>
            <div className="name">{option.name}</div>
        </div>
    )
    render() {
        const { tags, loading } = this.state;
        const { relatedTags } = this.props;
        const defaultProps = {
            options: tags,
            getOptionLabel: (option: RelatedTagType) => "",
        };
        return (
            <>
                <div id="added-airports">
                    <Grid container>
                        {relatedTags.map(this.renderTags)}
                    </Grid>
                </div>
                <div id="select-tags">
                    <Bookmarks className="flight-icon" />
                    <Autocomplete
                        loading={loading}
                        selectOnFocus={true}
                        renderOption={this.renderOptions}
                        onChange={this.handleSelect}
                        {...defaultProps}
                        filterSelectedOptions={true}
                        id="disable-close-on-select"
                        filterOptions={this.searchTags}
                        renderInput={(params) => (
                            <TextField onChange={this.loadTags} placeholder="Select Related tag" className="form-input primary" {...params} margin="normal" />
                        )}
                    />
                </div>
            </>
        )
    }
}

export default RelatedTags;