import React, { Component } from "react";
import Form from "../../../components/FormComponents";
import { fields } from "./fields"
import RightPannel from "../../../components/Authentication/right-pannel";
import Button from "../../../components/button";
import Loader from "../../../components/loader";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { notifError, notifSuccess } from "../../util";
import { updateAirlineAPIs } from "../../../services/api-services/airlines";
import { AirlineType } from ".";

interface StateParams {
    loading: boolean;
    initialValues: any;
}
function parseValue({ code, name, status, id }: AirlineType) {
    return {
        id,
        "Airline Name": name,
        "Airline Code": code,
        status: status ? "true" : "false"
    }

}
function parsePayload(payload: any): AirlineType {
    return {
        name: payload["Airline Name"],
        code: payload["Airline Code"],
        status: payload.status === "true" ? true : false
    }
}
class Edit extends Component<RouteComponentProps, StateParams> {
    state = {
        loading: false,
        initialValues: { status: "false" }
    }
    componentDidMount() {
        const { state }: any = this.props.location;
        const { code = 0 }: any = this.props.match.params;
        if (state && code) {
            const initialValues: AirlineType = state;
            this.setState({ initialValues: parseValue(initialValues), loading: false })
        } else {
            notifError("Error", "Please select a valid airline.")
            this.props.history.push("/filters/airlines")
        }
    }
    onSubmit = async (payload: any, resetForm: any) => {
        this.setState({ loading: true })
        let res = await updateAirlineAPIs(parsePayload(payload), payload.id);
        if (res.status) {
            notifSuccess("Airline", "Airline has been updated successfully.");
            this.props.history.push("/filters/airlines");
        }
        else notifError("Airline", `something went wrong`);
        this.setState({ loading: false });
    }
    handleCancel = () => {
        this.props.history.push("/filters/airlines")
    }
    render() {
        const { initialValues } = this.state;
        console.log("initialValues", initialValues)
        return (
            <div className="root-container form-center">
                <Loader show={this.state.loading} />
                <div className="flexBox-root">
                    <div id="add-tags">
                        <RightPannel
                            className="rightPanel-container"
                            from={0} handleChange={() => { }}>
                            <div className="rightPanel">
                                <div className="title">Edit Airline</div>
                                <div className="line"></div>
                                <div className="form">
                                    <Form
                                        fields={fields}
                                        onSubmit={this.onSubmit}
                                        initialValues={initialValues}
                                        btnText={"Update"}
                                        renderCustomSubmit={
                                            <div className="bottomSection">
                                                <Button onClick={this.handleCancel} btnText={"Cancel"} variant={"contained"} />
                                                <Button onClick={() => { }} btnText={"Update"} variant={"contained"} />
                                            </div>
                                        }
                                    />

                                </div>
                            </div>
                        </RightPannel>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Edit);